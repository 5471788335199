import React from 'react';
import List from '@material-ui/core/List';
import { FireTeamType } from '../../../types';
import FireTeamListItem from './FireTeamListItem';

const FireTeamList = ({ fireTeams }: { fireTeams: FireTeamType[] }): JSX.Element => {
  return (
    <List>
      {fireTeams.map((fireTeam, i) => {
        return <FireTeamListItem key={`${fireTeam.id}-${i}`} fireTeam={fireTeam} />;
      })}
    </List>
  );
};

export default FireTeamList;

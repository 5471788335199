import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import WeaponProfile from './WeaponProfile';
import FactionSelect from './FactionSelect';
import LeaderCheckbox from './LeaderCheckbox';
import UniqueKillTeamCheckbox from './UniqueKillTeamCheckbox';
import type { WeaponDataType, WeaponType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddFactionForm = ({
  onAdd,
  onEdit,
  selected,
  selectedFactionKeyword,
}: {
  onAdd: (value: Partial<WeaponDataType>) => void;
  onEdit: (value: WeaponType) => void;
  selected?: WeaponType;
  selectedFactionKeyword?: string;
}): JSX.Element => {
  const classes = useStyles();
  const [weapon, setWeapon] = React.useState<Partial<WeaponDataType>>();
  const [mode, setMode] = React.useState('ADD');
  const amountOfProfiles = weapon && weapon.profiles ? weapon.profiles.length : 0;

  const resetForm = () => {
    setWeapon(undefined);
    setMode('ADD');
  };

  const onResetClick = (e: any) => {
    resetForm();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (mode === 'ADD') {
      if (weapon) {
        onAdd(weapon);
      }
    } else {
      if (selected) {
        if (weapon) {
          onEdit({ ...selected, ...weapon });
        }
      }
    }

    resetForm();
  };

  React.useEffect(() => {
    if (selected) {
      setWeapon(selected);
      setMode('EDIT');
    }
  }, [selected]);

  React.useEffect(() => {
    if (selectedFactionKeyword) {
      setWeapon({ ...weapon, factionKeyword: selectedFactionKeyword });
    }
  }, [selectedFactionKeyword]);

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <Button onClick={onResetClick} fullWidth variant="contained" color="secondary">
        New
      </Button>
      <FormHelperText style={{ margin: '0.5rem 0' }}>{`${
        selected && selected.updatedAt ? formatDistanceInWords(new Date(selected.updatedAt)) : ''
      }`}</FormHelperText>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Type"
        name="type"
        onChange={(e) => setWeapon({ ...weapon, type: e.target.value })}
        value={weapon?.type || ''}
        helperText={'Example: Chainsword, Heavy bolt pistol'}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="descr"
        label="Description"
        name="descr"
        autoComplete="descr"
        onChange={(e) => setWeapon({ ...weapon, descr: e.target.value })}
        value={weapon?.descr || ''}
        helperText="Example: Each time this operative..."
        multiline
        maxRows={4}
      />
      <UniqueKillTeamCheckbox
        onChange={(isKillTeamUnique) => setWeapon({ ...weapon, isKillTeamUnique })}
        selected={!!weapon?.isKillTeamUnique}
      />
      <FactionSelect
        onChange={(factionKeyword) => setWeapon({ ...weapon, factionKeyword })}
        selected={weapon?.factionKeyword}
      />
      <LeaderCheckbox
        onChange={(isLeaderType) => setWeapon({ ...weapon, isLeaderType })}
        selected={!!weapon?.isLeaderType}
      />
      <Typography variant="h6">Weapon Profiles</Typography>
      <WeaponProfile
        index={amountOfProfiles}
        profiles={weapon?.profiles || []}
        setProfiles={(profiles) => setWeapon({ ...weapon, profiles })}
      />
      {weapon &&
        weapon.profiles &&
        weapon.profiles.map((profile, i) => (
          <WeaponProfile
            key={i}
            index={i}
            profile={profile}
            profiles={weapon?.profiles || []}
            setProfiles={(profiles) => setWeapon({ ...weapon, profiles })}
          />
        ))}
      <Alert severity="warning">
        Glöm inte att först klicka på &apos;edit profile&apos; knappen och sedan blåa knappen ifall du gjort någon
        ändring på profilen!
      </Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={weapon && !weapon.type}
      >
        {mode === 'ADD' ? 'Add' : 'Edit'} Weapon
      </Button>
    </form>
  );
};

export default AddFactionForm;

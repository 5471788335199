import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useStateContext } from './StateProvider';
import { NavLink } from 'react-router-dom';
import { HOME } from '../constants/routes';
import Navigation from './Navigation';
import SignUpButton from './SignUpButton';
import LoginButton from './LoginButton';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const Header = ({ title }: { title: string }): JSX.Element => {
  const [
    {
      context: { user },
    },
  ] = useStateContext();
  const classes = useStyles();

  return (
    <>
      <Toolbar className={classes.toolbar}>
        {!user ? <SignUpButton /> : <p>{user.firstName}</p>}
        <Link component={NavLink} to={HOME} color="inherit" noWrap variant="body2" className={classes.toolbarTitle}>
          <Typography component="h2" variant="h5" color="inherit" align="center" noWrap>
            {title}
          </Typography>
        </Link>
        <LoginButton />
      </Toolbar>
      <Navigation />
    </>
  );
};

export default Header;

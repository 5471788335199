import React from 'react';
import type { IconType } from './icon.types';

const Thousands = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 862.1 826.2"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path className="st0" d="M649.9,87.7l1-1.5C649.1,85.7,649,86.4,649.9,87.7z" />
      <path
        d="M862.1,527.2c-4-15.7,1.2-32.3-0.5-48.4c-3-28.7-27.4-51.3-32.2-79.8c-0.6-3.7-0.9-7.7,0.7-11.2c1.6-3.4,5.6-6,9.2-5
		c2.5,0.7,4.3,2.8,5.7,4.9c5.5,7.8,8.7,17.2,9.3,26.8c7.4-16.2,5.9-35.5-1.4-51.7c-7.3-16.2-19.9-29.5-34-40.3
		c-5.7-4.4-11.8-8.5-16-14.3c-1-1.3-15.9-32.4-19.3-44.2c-3.7-13-3.1-34.1-7.6-42.3c0.1,0.1,0.3,0.2,0.5,0.3
		c13.8,7.6,24.9,11.5,27.6,12.5c7.1,2.6,17.5,8.2,32.1,21.2c-7.6-21.3-19.7-32.2-26.3-40.6c-17.9-22.6-50.6-28.7-69.9-50.2
		c-2.5-2.8-4.9-6-5.4-9.8s1.5-8.1,5.1-9.2c2.4-0.7,5.1,0.1,7.4,1.1c8.8,3.7,16.6,9.9,22.2,17.7c-2.5-17.6-14.1-33.1-28.9-42.9
		s-32.6-14.4-50.3-15.9c-7.2-0.6-14.5-0.8-21.2-3.5c-3.4-1.4-33-16.4-51.9-26.8c2.8-2,6.4-2.6,9.9-3c22.7-2.9,45.3-5.9,68-8.8
		C678.8,62,665.2,51.1,649.5,47c-28-7.4-57.8,7.3-86.1,1.7c-3.7-0.7-7.5-1.9-10.2-4.6c-2.6-2.7-3.7-7.4-1.4-10.4
		c1.5-2,4.1-2.9,6.6-3.5c9.3-2.3,19.3-2,28.4,0.9C574.3,18.5,555.7,13,538,14s-34.7,8-49.8,17.4c-6.1,3.8-12.1,8-19,9.9
		c-4.3,1.1-15.2,1-21.2,0.2c0.1-0.7,0.3-1.3,0.6-2c1.7-3.8,5.6-5.9,9.3-7.7c20.5-10.2,40.9-20.4,61.4-30.6
		c-15.8,3.6-32.2-2.2-48.4-1C442.2,2.4,418.8,26,390.2,30c-3.8,0.6-7.7,0.7-11.2-1s-6-5.7-4.8-9.3c0.8-2.4,3-4.1,5.1-5.5
		c8-5.2,17.5-8.1,27.1-8.4c-15.9-7.8-35.3-6.9-51.7-0.1c-16.5,6.8-30.2,19-41.4,32.8c-4.5,5.6-8.7,11.6-14.7,15.6
		c-6,4.1-28.9,10.6-23.5,5.9c2.9-2.6,26-39,19-37c-23,19-47.2,27.3-58.4,51.1c-2.6,5.6-5.6,13.2-11.8,13c-6.6-0.1-8.9-9-8.5-15.7
		C216.7,46,227,21.1,243.9,2.1c-25.6,21.7-46.1,36.2-54.9,82.8c-2.1,6.7-4,13.7-8.3,19.2c-5.4,6.8-13.8,10.3-20.5,15.9
		c-13,10.9-18.3,28.7-30.5,40.4c-1.9,1.9-4,3.5-6.1,5.1c-9.9,7.3-16.7,12.4-18.3,13.6c-20.7,16.4-8.7,57.4-8.8,56.8
		c3.9-1.1,24.3-41.1,52.3-44.7c5.2-0.6,8.1-2.3,10.5-1c4.7,2.5,5.5,10,5.4,13.3c-0.6,21.5-8.9,42.7-23,58.9c9.5,1.7,20.6,3,27.7-3.6
		c3.4-3.2,5.1-7.6,7.1-11.8c9.1-19.6,24.1-36.3,42.6-47.4c6-3.6,13.2-7.8,13.5-14.8c0.2-3.8-1.9-7.4-2.5-11.2
		c-1.8-11.4,10.1-20.7,21.5-22.1c11.3-1.4,22.7,2.3,34.2,2.6s24.8-4.3,28.1-15.3c0.8-2.7,1.4-5.9,2.6-8.4c1.2-2.5,11.4-4,14-3
		c-6.8-7.1-25.9,1.3-35.8,1.2c-1.6,0-3.2-0.3-4.5-1.2c-2.8-2-2.9-6.5-0.9-9.4c2-2.9,5.3-4.5,8.6-5.7c16.3-5.8,34.9-4.8,50.3,2.9
		c6.7,3.3,12.9,7.9,20,10.3c14,4.8,30.8,0,40.2-11.4c-4.7,0.3-9.7,0.5-13.6-2.1c-3.8-2.6-5.3-9.1-1.5-11.8c2.3-1.6,5.5-1.2,8.3-0.7
		c15.2,2.8,31.1,5.8,43.3,15.3c6.9,5.4,12.1,12.8,19.1,18c18.1,13.6,30.5,18.5,53,16c-22.4-9.9-36.7-27.2-46.2-49.2
		c9.4,3.2,18.1,8.6,25.1,15.7c5.2,5.4,9.6,11.7,15.5,16.3c11.7,9.2,29.1,10.2,41.8,2.5c-4.5-1.2-9.3-2.7-12.1-6.4
		c-2.8-3.7-2.1-10.3,2.4-11.6c2.8-0.7,5.6,0.7,8.1,2.1c13.5,7.6,7.5,15.6,15.9,28.6c4.7,7.3,7.4,15.9,12.3,23.1
		c12.6,18.7,36.6,25.4,58.7,30.4c-20-18.6-41.5-40.2-42-67.5c0-1.7,0.5-3.5,1.4-5.2c16.5,12.2,45.9,35,48,50.1
		c1,7.5,0.7,15.1,2.7,22.4c3.9,14.3,17.2,25.6,32,27c-2.9-3.7-5.9-7.7-5.9-12.4c0-4.7,4.5-9.5,8.9-7.9c2.6,1,4,3.9,5.2,6.5
		c6.2,14.1,12.6,29,11.6,44.4c-0.5,8.7-3.5,17.2-3.9,25.9c-1.1,22.6,14.1,42.3,28.8,59.5c-5-26.8-9.2-57,6.7-79.2
		c0.1-0.1,0.1-0.1,0.2-0.2c7.9,14,11.6,26.6,13.4,35.3c2.6,12.2,7.8,36.6-2.5,60.6c-4.6,10.6-7.1,11.9-9.7,20.3
		c-4.4,14.2,0.9,30.8,12.6,39.9c-0.5-4.6-0.8-9.6,1.6-13.6s8.9-5.6,11.7-1.9c1.7,2.3,1.3,5.5,0.9,8.3c-2.3,15.2-4.8,31.2-13.9,43.7
		c-5.1,7.1-12.1,12.7-17.1,19.8c-13,18.5-10.7,43.3-7.5,65.7c9.9-24.6,22-51.6,46-62.5c-2.8,16.1-8.1,28.3-12.1,36.2
		c-5.5,10.5-15,28.7-34.4,40.1c-10.5,6.2-13.3,5.2-20.5,10.3c-12.3,8.7-18.3,25.4-14.3,40c2.5-4,5.3-8.2,9.7-9.9
		c4.4-1.6,10.7,1.1,10.7,5.8c0,2.9-2.2,5.2-4.3,7.2c-11.3,10.9-13.3,2.1-28.4,6.5c-8.6,2.5-17.7,2.6-26.2,5.2
		c-22,6.7-35.4,28.2-46.6,48.2c23.9-14,51.4-28.6,78.3-21c0.3,0.1,0.6,0.2,0.8,0.3c-17.7,15.7-41.5,41-53.7,36.2
		c-7-2.7-13.6-6.7-20.9-8.5c-14.4-3.5-30.7,2.7-39.1,14.9c4.6-0.8,9.6-1.4,13.7,0.8s6.2,8.6,2.6,11.6c-2.2,1.8-5.4,1.7-8.2,1.4
		c-15.4-1.4-63.3-3-70.7-7.6c-24-3-28.4,12.1-50.6,16.9c15.3,5.1,31.5,11,44.7,20.2c-4.1,1-10.8,2.5-19.1,3.4
		c-16.6,1.9-38.5,4.4-57.4-6.8c-9.7-5.7-8.7-8.4-17.2-13.3c-12.6-7.3-29.8-7.7-41.3-0.9c4.6,0.9,9.5,2,12.7,5c3.3,3,3.3,8.6-0.9,9.8
		c-2.6,0.7-5.6-0.4-8.2-1.5c-14.2-6-28.9-12.4-38.8-23.1c-5.5-6-9.2-13-14.9-19c-14.7-15.4-39.1-20.4-61.4-24
		c20.1,13.8,41.8,29.7,48.1,49.9c-5.6-1.6-13.7-4.4-22.7-9.1c-13.5-7-32.4-16.9-39.6-35.9c-4-10.4-1.3-13-4.6-22.1
		c-5.1-13.9-19.4-24-34.2-24.1c3.1,3.4,6.5,7.2,6.9,11.8c0.4,4.6-3.7,9.9-8.2,8.6c-2.8-0.8-4.4-3.5-5.8-6
		c-7.4-13.5-15.1-27.7-15.4-43.2c-0.2-8.7,2-17.4,1.7-26.1c-0.9-22.6-17.8-40.9-33.9-56.8c7.1,25.5,13.7,54.2,1.4,77.4
		c-12.5-23.3-35.5-60.7-31.3-71.8c2.4-6.3,5.6-12,7.3-18.6c3.4-13,0.1-28.5-8-37.3c0.2,4.3,0.3,9-1.5,12.6c-1.8,3.6-6.4,4.9-8.3,1.4
		c-1.2-2.2-0.9-5.1-0.5-7.7c1.9-14.1,4.1-28.8,10.8-40.1c3.7-6.4,8.8-11.3,12.5-17.8c9.6-16.8,8.6-39.9,6.8-60.7
		c-7.7,23.2-17.2,48.6-35.3,57.3c-0.9,0.4-1.7,0.8-2.6,1.1c11.3-21.3,0.2-34.8,13.1-59.5c6.4-12.4,10.3-11.1,15.3-23.7
		c7.6-18.9,0-44.7,0-44.7s-8,27.3-21.3,21.7c-13.3-5.7,3.7-24.3,13.7-31.7s25-51.4,24-52c-1.7-1.1-5.7,18.7-22.7,24.7
		c-10.6,3.8-32.4,20.9-40.3,40.3c-6.1,15,0.3,18.8-2,62.7c-0.7,13.4-2.2,40.6-7,41c-3.4,0.3-4-12-9.7-23.7
		c-5.7-11.7-0.3-23.3-0.3-23.3s-14.5,9.5-16.3,23.3c-1.8,13.9,7.7,17.6,13.7,40.7c2.2,8.4,2.7,14.8,3,19.5c-2.2-1.2-4.8-2.4-7-3.8
		c-12-7.5-21.3-17.7-24.3-37.3c2.3,14.7-6.7,15,0,31c10.3,24.7,24.3,39.2,27,65.7c0.4,3.5,0.5,7.2-0.7,10.4s-4,5.5-6.6,4.4
		c-1.7-0.7-2.9-2.7-3.9-4.7c-3.7-7.5-5.7-16.3-5.9-25.2c-5.6,14.8-5,32.8-0.2,48c4.7,15.2,13.3,28,23,38.4c2.1,2.2,4.3,4.6,6.7,7.7
		c2.4,3,3.9,5.4,4.3,6c7.1,10.8,27.9,25,26.3,29.6c-1,2.8-45.6-9.2-48.5-18.5c6,19.5,27,27.5,47.1,39.8c9.6,5.9,13.5,22.8,9.9,26.2
		c-2.4,2.2-8.5-0.7-22-8c-10.6-5.7-25.1-13.7-42.5-23.9c3.2,8.4,9,19.9,19.8,30.1c3.2,3,6.2,5.4,10,8.1c22.8,15.9,59.2,29.7,74,43.9
		c2.7,2.6,5.4,5.6,6.2,9.3c0.9,3.7-0.7,8.2-4.2,9.6c-2.4,0.9-5.1,0.3-7.5-0.5c-9.1-2.9-17.4-8.4-23.6-15.7
		c4,17.4,16.9,31.8,32.5,40.3c15.6,8.5,33.7,11.5,51.5,11.5c9.4,0,7,0.3,21.4,1.6c47.3,4.2,65,11.1,72.4,14.3
		c6.2,2.7,11.1,5.4,14.1,7.1c-3.1,1-6.2,2.5-9.5,2.9c-21.9,3.1-43.8,6.1-65.7,9.2c16.1,1.1,30.8,9.8,46.7,12.9
		c28.4,5.5,55.9-7.7,84.4-3.7c3.7,0.5,7.6,1.4,10.6,3.6s4.6,6.1,2.7,8.7c-1.2,1.8-3.6,2.6-6,3.2c-8.9,2.2-18.7,2.2-28,0
		c13.9,10.2,32.8,14.3,50.1,13c17.3-1.3,33.1-7.6,46.8-15.9c9.6-5.8,9.5-5.5,17.5-8.8c16.4-6.7,31-8.8,41.8-10.3
		c5.4-0.7,12.6-1.5,21.4-1.9c-3.7,6.5-9.4,14.8-17.9,22.9c-14.9,14.2-30.9,20.4-40.2,23.2c15.4-5,32.3-0.7,48.3-3.4
		c28.5-4.8,32.4-43.7,81.8-49.9c3.7-0.5,7.6-1.4,11.2,0s6.4,5.2,5.6,8.9c-0.6,2.4-2.6,4.3-4.6,5.9c-7.6,5.9-16.8,9.7-26.3,10.8
		c16.6,6.3,35.7,3.7,51.5-4.5s28.4-21.5,38.3-36.3c4.5-6.8,6.3-8.6,13.3-16.9c11.1-13.2,16.2-17.1,23.6-25.8
		c5.6-6.6,13.1-16.5,20.3-30.4c0.3,2.6,1.2,6.6,0.5,11.3c-3.6,23-7.1,45.9-10.7,68.9c6.3-15.2,20.9-25.3,29.4-39.3
		c15.2-25,9.3-58.5,22.9-84.4c1.8-3.4,4.1-6.8,7.5-8.6s8.3-1.4,10.6,1.7c1.6,2.1,1.7,4.9,1.6,7.5c-0.4,9.8-3.6,19.3-9,27.4
		c16-8.4,26.6-24.9,30.7-42.5s2-36.2-2.9-53.7c-3.5-12.6-4.4-14-4.3-21.5c0.1-17.3,9.3-26.1,16.4-41.9c4.5-10,9.3-25.4,8.7-47.5
		c0.7,1.1,1.8,2.7,2.9,4.8C840.5,486.9,851.3,507.1,862.1,527.2z"
      />
      <path className="st0" d="M808.9,528.1l1.8,0.4C810.5,526.6,809.8,526.7,808.9,528.1z" />
      <path className="st0" d="M703.6,731.3l0.9,1.6C705.7,731.5,705.2,731.1,703.6,731.3z" />
      <path className="st0" d="M481,792l0.4,1.5C482.9,792.8,482.5,792.3,481,792z" />
      <path className="st0" d="M241.4,758.7l-0.8,1.6C242.4,760.6,242.4,759.9,241.4,758.7z" />
      <path d="M5.1,388C-15.5,433.2,33,442.6,33,442.6C12.5,429,7.5,403.3,5.1,388z" />
    </g>
  </svg>
);

export default Thousands;

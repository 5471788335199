import React from 'react';
import type { IconType } from './icon.types';

const ChaosStar = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 879.8 879.8"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <polygon
      points="879.8,442.4 782.2,344.7 782.2,411.9 511.3,411.9 703.6,219.6 751,267 751,128.9 612.9,128.9 660.4,176.4
	470.5,366.2 470.5,97.7 537.6,97.7 439.9,0 342.2,97.7 409.4,97.7 409.4,369.8 217.7,178.1 265.2,130.6 127.1,130.6 127.1,268.7
	174.5,221.3 365.1,411.9 97.7,411.9 97.7,344.7 0,442.4 97.7,540.1 97.7,473 363.7,473 176.4,660.4 128.9,612.9 128.9,751 267,751
	219.6,703.6 409.4,513.7 409.4,782.2 342.2,782.2 439.9,879.8 537.6,782.2 470.5,782.2 470.5,517.3 658.5,705.3 611.1,752.7
	749.3,752.7 749.3,614.6 701.8,662.1 512.7,473 782.2,473 782.2,540.1 "
    />
  </svg>
);

export default ChaosStar;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withAuth } from '../../Auth';
import List from '../List';
import AddFactionForm from './AddFactionForm';
import type { FactionDataType, FactionType, SnackbarType } from '../../../types';
import { useStateContext } from '../../StateProvider';
import FormLayout from '../FormLayout';

const AddFaction = () => {
  const [
    {
      matches,
      context: { factions, fireTeams },
    },
    send,
  ] = useStateContext();
  const [selectedFaction, setSelectedFaction] = React.useState<FactionType>();

  const onAddFaction = (faction: Partial<FactionDataType>): void => {
    const snackbar: SnackbarType = {
      type: 'success',
      text: `${faction.type} added!`,
    };
    send('ADD_FACTION', { faction, snackbar });
  };

  const onEditFaction = (faction: Partial<FactionType>) => {
    const snackbar: SnackbarType = {
      type: 'warning',
      text: `${faction.type} updated!`,
    };
    send('EDIT_FACTION', { faction, snackbar });
  };

  const onDelete = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      const snackbar: SnackbarType = {
        type: 'error',
        text: `Faction deleted!`,
      };
      send('DELETE_FACTION', { id, snackbar });
    }
  };

  const onSelectFaction = (id: string) => {
    const selectFaction: FactionType = factions.find((f: FactionType) => f.id === id);

    if (selectFaction) {
      setSelectedFaction(selectFaction);
    }
  };

  if (matches({ authorized: 'editingFaction' }) || matches({ authorized: 'addingFaction' }))
    return <CircularProgress />;

  return (
    <FormLayout
      noFilter
      title="Add Faction"
      section1={
        factions ? (
          <List
            title={`Factions (${factions.length})`}
            items={factions.sort((a: FactionType, b: FactionType) => {
              if (a.type > b.type) {
                return 1;
              }
              if (a.type < b.type) {
                return -1;
              }
              return 0;
            })}
            onItemClick={onSelectFaction}
            onItemDelete={onDelete}
          />
        ) : (
          <CircularProgress />
        )
      }
      section2={
        <AddFactionForm
          fireTeams={fireTeams}
          onAddFaction={onAddFaction}
          onEditFaction={onEditFaction}
          selectedFaction={selectedFaction}
        />
      }
    />
  );
};

export default withAuth(AddFaction);

const devConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_apiKey,
  authDomain: process.env.REACT_APP_PROD_authDomain,
  databaseURL: process.env.REACT_APP_PROD_databaseURL,
  projectId: process.env.REACT_APP_PROD_projectId,
  storageBucket: process.env.REACT_APP_PROD_storageBucket,
  messagingSenderId: process.env.REACT_APP_PROD_messagingSenderId,
  appId: process.env.REACT_APP_PROD_appId,
  measurementId: process.env.REACT_APP_PROD_measurementId,
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
console.log(process.env, { config });
export default config;

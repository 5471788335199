import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStateContext } from '../StateProvider';
import { withAuth } from '../Auth';
import TeamsList from './TeamsList';
import Modal from '../Modal';
import AddTeamForm from './AddTeamForm';

const MyTeams = () => {
  const [current, send] = useStateContext();
  const { addTeamModalVisible } = current.context;

  const handleOpen = () => {
    send('OPEN_ADD_TEAM_MODAL');
  };

  const handleClose = () => {
    send('CLOSE_ADD_TEAM_MODAL');
  };
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography component="h1" variant="h5">
            My Teams
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" size="medium" color="primary" style={{ margin: '0 0 1rem' }} onClick={handleOpen}>
            Add Team
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {current.matches({ authorized: 'loadingUserTeams' }) ? <CircularProgress /> : <TeamsList />}
      </Grid>
      <Modal isOpen={addTeamModalVisible} onClose={handleClose} title={<h1>Add New Team</h1>}>
        <AddTeamForm />
      </Modal>
    </Grid>
  );
};

export default withAuth(MyTeams);

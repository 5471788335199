import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Filter from './Filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const FormLayout = ({
  noFilter,
  title,
  section1,
  section2,
}: {
  noFilter?: boolean;
  title: string;
  section1?: JSX.Element;
  section2?: JSX.Element;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" style={{ marginTop: '1rem' }}>
            {title}
          </Typography>
          {!noFilter && <Filter />}
        </Grid>
        <Grid item sm={5} xs={12}>
          <Paper className={classes.paper}>{section1}</Paper>
        </Grid>
        <Grid item sm={7} xs={12}>
          <Paper className={classes.paper}>{section2}</Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormLayout;

import React from 'react';
import {
  AdeptusMechanicus,
  Alaitoc,
  AstraMilitarum,
  ChaosStar,
  DeathGuard,
  Deathwatch,
  Drukhari,
  GreyKnights,
  ImperialAquila,
  IronJaws,
  Necrons,
  Tau,
  TheHivecult,
  ThousandSons,
  Tyranids,
} from '../assets/icons';

export const createIconName = (factionName: string): string => {
  const names = factionName.split(' ');
  return names.reduce((iconName, name) => {
    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);
    return iconName + nameCapitalized;
  }, '');
};

const Icon = ({ name, className }: { name: string; className?: string }): JSX.Element => {
  const iconName = createIconName(name);

  switch (iconName) {
    case 'AdeptusMechanicus':
      return <AdeptusMechanicus className={className && className} />;
    case 'AstraMilitarum':
      return <AstraMilitarum className={className && className} />;
    case 'HereticAstartes':
      return <ChaosStar className={className && className} />;
    case 'DeathGuard':
      return <DeathGuard className={className && className} />;
    case 'Deathwatch':
      return <Deathwatch className={className && className} />;
    case 'Drukhari':
      return <Drukhari className={className && className} />;
    case 'GreyKnights':
      return <GreyKnights className={className && className} />;
    case 'Greenskin':
    case 'Commando':
      return <IronJaws className={className && className} />;
    case 'Necrons':
      return <Necrons className={className && className} />;
    case "T'auEmpire":
      return <Tau className={className && className} />;
    case 'GenestealerCults':
      return <TheHivecult className={className && className} />;
    case 'ThousandSons':
      return <ThousandSons className={className && className} />;
    case 'Tyranids':
      return <Tyranids className={className && className} />;
    case 'Asuryani':
    case 'Harlequins':
      return <Alaitoc className={className && className} />;
    default:
      return <ImperialAquila className={className && className} />;
  }
};

export default Icon;

export const DEATH_GUARD = 'Death Guard' as const;
export const ADEPTUS_ASTARTES = 'Adeptus Astartes' as const;
// export const TAU_EMPIRE = "Ta'u Empire";
// export const CHAOS_DAEMONS = 'Chaos Daemons';
// export const GENESTEALER_CULTS = 'Genestealer Cults';
// export const KROOT = 'Kroot';
// export const VETERAN_GUARDSMAN = 'Veteran Guardsman';
// export const TALONS_OF_THE_EMPEROR = 'Talons of the Emperor';
export const GREENSKIN = 'Greenskin' as const;
export const KOMMANDO = 'Kommando' as const;
export const NECRONS = 'Necrons' as const;

export const factionArray = [ADEPTUS_ASTARTES, DEATH_GUARD, NECRONS, GREENSKIN, KOMMANDO];

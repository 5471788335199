import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withAuth } from '../../Auth';
import List from '../List';
import AddLoadoutForm from './AddLoadoutForm';
import { sortByType, isInComplete } from './AddLoadout.helper';
import type { LoadoutType, LoadoutDataType, SnackbarType } from '../../../types';
import { useStateContext } from '../../StateProvider';
import Filter from '../Filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const AddAbilities = () => {
  const classes = useStyles();
  const [state, send] = useStateContext();
  const { loadouts, filterBy }: { loadouts: LoadoutType[]; filterBy: string } = state.context;
  const [selected, setSelected] = React.useState<LoadoutType>();
  const sortedLoadouts = loadouts
    ? loadouts.sort(sortByType).filter((l) => (filterBy ? l.factionKeyword === filterBy : true))
    : [];

  const onAdd = (loadout: LoadoutDataType): void => {
    const snackbar: SnackbarType = {
      type: 'success',
      text: `${loadout.type} added!`,
    };
    send('ADD_LOADOUT', { loadout, snackbar });
  };

  const onEdit = (loadout: LoadoutType) => {
    const snackbar: SnackbarType = {
      type: 'warning',
      text: `${loadout.type} updated!`,
    };
    send('EDIT_LOADOUT', { loadout, snackbar });
  };

  const onDelete = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      const snackbar: SnackbarType = {
        type: 'error',
        text: `Loadout deleted!`,
      };
      send('DELETE_LOADOUT', { id, snackbar });
    }
  };

  const onSelect = (id: string) => {
    const select = loadouts.find((L: LoadoutType) => L.id === id);

    if (select) {
      setSelected(select);
    }
  };

  if (state.matches({ authorized: 'editingLoadout' }) || state.matches({ authorized: 'addingLoadout' }))
    return <CircularProgress />;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" style={{ marginTop: '1rem' }}>
            Add Loadout
          </Typography>
          <Filter />
        </Grid>
        <Grid item sm={5} xs={12}>
          <Paper className={classes.paper}>
            {loadouts !== undefined ? (
              <List
                title={`Loadouts (${sortedLoadouts.length} / ${loadouts.length})`}
                items={sortedLoadouts as unknown as Record<string, unknown>[]}
                onItemClick={onSelect}
                inComplete={isInComplete}
                onItemDelete={onDelete}
              />
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </Grid>
        <Grid item sm={7} xs={12}>
          <Paper className={classes.paper}>
            <AddLoadoutForm onAdd={onAdd} onEdit={onEdit} selected={selected} filterBy={filterBy} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withAuth(AddAbilities);

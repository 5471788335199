import React from 'react';
import type { IconType } from './icon.types';

const Alaitoc = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_2_copy"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M652,329.3c0-33.5-12.2-64.1-32.4-87.6c2.3,8.7,3.5,17.8,3.5,27.1c0,44.6-27.6,82.7-66.6,98.3
	c4.7-41.4,11.3-74.2,18.6-100.1c-9.2,2.9-20,1.4-28.9-5.1c-14.1-10.3-17.9-29.1-8.5-42c9.4-12.9,28.5-15,42.6-4.7
	c3.9,2.8,7,6.3,9.2,10.2c17.5-41.1,34.2-51.5,34.2-51.5s-88,22-90.7,5.5l-1.4-112.7c0,0,15.1-8.2,15.1-24.7S526.2,3.5,516.6,3.5
	c-9.6,0-30.2,22-30.2,38.5s15.1,24.7,15.1,24.7l-1.4,112.7c-2.7,16.5-90.7-5.5-90.7-5.5s16.7,10.4,34.2,51.5
	c2.3-3.8,5.3-7.3,9.2-10.2c14.1-10.3,33.2-8.2,42.6,4.7c9.4,12.9,5.6,31.7-8.5,42c-8.9,6.5-19.7,8-28.9,5.1
	c7.3,25.8,13.9,58.4,18.6,99.5c-38.2-16-65.1-53.7-65.1-97.7c0-9.4,1.2-18.5,3.5-27.1c-20.2,23.6-32.4,54.2-32.4,87.6
	c0,62.5,42.6,115.1,100.3,130.3c1.2,41.3,0.7,88.3-2.1,141.9c0,23.4-13.7,101.7-13.7,221.3c0,70.1,49.5,173.2,49.5,173.2
	s49.5-103.1,49.5-173.2c0-119.6-13.7-197.9-13.7-221.3c-2.8-53.5-3.3-100.4-2.1-141.5C608.7,445.2,652,392.3,652,329.3z"
    />
  </svg>
);

export default Alaitoc;

import React from 'react';
import type { IconType } from './icon.types';

const CStudio = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 730.000000 608.000000"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g transform="translate(0.000000,608.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M3025 5870 c-365 -25 -710 -119 -1080 -296 -454 -216 -921 -667
-1194 -1150 -158 -281 -277 -642 -332 -1004 -33 -214 -33 -586 -2 -790 80
-511 250 -928 535 -1307 426 -568 971 -932 1629 -1087 256 -61 602 -89 803
-66 89 10 110 10 117 -1 4 -7 5 -17 3 -21 -3 -4 0 -8 5 -8 6 0 11 4 11 9 0 5
13 14 30 21 16 7 28 16 25 20 -3 4 16 11 41 15 177 26 530 143 717 237 63 31
82 37 93 27 20 -16 115 -4 126 16 5 8 18 15 29 15 23 0 42 40 37 79 -2 21 7
33 52 65 106 76 206 161 290 246 82 83 228 262 265 325 l17 30 -23 -26 c-13
-14 -61 -74 -107 -132 -103 -131 -265 -288 -401 -389 -90 -68 -105 -76 -122
-65 -19 12 -19 12 -1 27 10 8 29 22 43 31 13 9 22 19 19 23 -3 3 -26 -8 -50
-24 -25 -17 -55 -30 -67 -30 -12 0 -38 -16 -58 -35 -19 -20 -40 -33 -45 -30
-6 3 -10 1 -10 -4 0 -6 -3 -10 -7 -9 -5 2 -50 -19 -100 -45 -67 -34 -93 -44
-93 -33 0 8 -2 21 -5 29 -4 10 27 28 113 66 249 109 439 238 630 428 82 82
127 120 137 117 18 -7 45 12 45 32 0 8 -7 14 -16 14 -14 0 -15 2 -3 19 154
212 264 420 333 631 69 212 80 280 71 443 -10 187 -24 184 -46 -9 -6 -54 -17
-114 -25 -132 -7 -19 -46 -68 -86 -109 -64 -65 -69 -74 -44 -67 26 6 28 5 21
-17 -4 -13 -14 -35 -23 -49 -8 -13 -18 -38 -22 -55 -7 -30 -11 -32 -41 -20
-11 4 -20 -5 -32 -32 -10 -21 -28 -56 -42 -78 -14 -22 -25 -49 -25 -61 0 -11
-12 -31 -26 -45 -14 -13 -50 -60 -80 -105 -29 -44 -61 -87 -71 -95 -9 -8 0 8
20 35 59 78 43 77 -32 -2 -71 -74 -90 -85 -92 -54 0 9 -9 -2 -19 -25 -20 -46
-28 -51 -48 -31 -10 10 -23 1 -63 -45 -28 -31 -54 -57 -59 -57 -10 0 5 58 25
96 16 31 5 64 -20 64 -21 0 -19 14 5 35 31 28 24 59 -25 116 -30 35 -45 62
-45 80 0 35 -33 48 -59 25 -20 -18 -41 -22 -23 -4 20 20 14 35 -18 48 -21 9
-30 19 -30 35 0 31 -63 56 -158 63 l-72 5 -55 -52 c-29 -28 -58 -51 -64 -51
-6 0 -8 -6 -5 -13 2 -7 -14 -30 -36 -52 -22 -21 -40 -43 -40 -48 0 -5 16 -7
35 -4 26 4 35 2 35 -8 0 -23 -16 -26 -61 -10 -41 15 -47 14 -104 -6 -33 -12
-66 -27 -73 -32 -7 -5 -27 -17 -45 -27 -25 -14 -33 -24 -35 -51 -3 -45 10 -63
50 -66 18 -1 49 -6 68 -11 l35 -8 -40 -14 c-22 -8 -47 -14 -55 -15 -8 -1 -53
-14 -100 -28 -112 -34 -124 -35 -148 -14 -18 17 -28 17 -119 7 -54 -6 -184
-10 -288 -8 l-190 3 -121 42 c-132 45 -274 113 -371 176 -49 32 -60 44 -53 57
5 9 5 22 -1 31 -12 19 -11 69 1 69 19 0 10 23 -25 62 -20 21 -38 52 -42 69 -4
21 -10 28 -22 24 -9 -3 -37 10 -64 29 l-48 35 -62 -24 c-77 -28 -82 -26 -172
95 -56 76 -67 96 -72 142 -6 59 -25 88 -58 88 -18 0 -30 17 -67 97 -123 264
-192 604 -154 748 9 33 19 121 22 195 6 117 11 149 41 240 65 197 173 433 253
550 48 71 214 229 319 304 348 249 698 387 1080 427 357 37 717 -71 1068 -320
74 -54 92 -72 104 -105 17 -48 46 -66 110 -66 46 0 71 -13 63 -34 -2 -6 20
-28 50 -49 29 -21 73 -59 97 -83 24 -24 50 -44 58 -44 8 0 30 -14 48 -32 83
-81 -8 60 -126 195 -28 32 -51 60 -51 62 0 3 15 11 33 19 19 7 43 26 55 40 18
24 25 26 58 20 23 -4 35 -3 33 3 -6 15 -116 105 -170 139 -25 16 -44 34 -42
41 4 12 -94 79 -194 134 -96 52 -64 48 42 -5 107 -53 261 -165 345 -250 47
-48 59 -56 92 -56 21 0 38 4 38 9 0 19 -271 270 -358 331 -205 144 -414 242
-653 305 -118 32 -126 35 -70 30 96 -9 281 -69 416 -134 223 -107 384 -219
601 -419 131 -120 132 -120 192 -64 45 42 42 72 -16 168 -100 165 -252 382
-379 542 -41 51 -73 98 -71 103 4 13 136 -80 251 -178 86 -74 129 -93 71 -32
-33 35 -213 192 -259 226 -16 12 -27 23 -24 23 13 0 197 -146 287 -229 152
-139 384 -420 465 -563 21 -35 37 -49 37 -30 0 12 -97 174 -157 263 -114 169
-293 369 -458 512 -38 34 -69 64 -68 68 3 8 -154 121 -257 186 -128 80 -274
155 -393 202 -42 17 -85 39 -96 50 -10 10 -43 27 -72 36 -30 10 -67 27 -82 37
-23 17 -36 19 -75 12 -26 -4 -49 -7 -52 -7 -3 1 -60 13 -128 27 -228 48 -485
64 -757 46z m990 -556 c111 -14 164 -27 365 -93 l235 -77 102 -130 c56 -71
102 -132 103 -136 0 -4 -42 18 -94 50 -51 31 -89 59 -85 60 15 6 10 44 -8 64
-21 23 -63 24 -63 1 0 -9 7 -25 17 -35 11 -12 -14 -4 -73 23 -105 49 -325 135
-444 175 -71 23 -103 28 -190 29 -338 2 -370 4 -370 24 0 14 6 17 26 13 27 -5
198 18 190 25 -2 3 -26 11 -53 19 -46 13 -41 13 82 8 72 -2 189 -12 260 -20z
m-936 -118 c-2 -2 -69 -29 -149 -61 -80 -32 -198 -84 -262 -117 -65 -32 -118
-56 -118 -53 0 11 188 130 249 158 82 37 307 96 280 73z m1091 -3781 c0 -7 5
-15 12 -17 17 -6 -201 -111 -294 -142 -59 -20 -87 -24 -123 -19 l-47 6 98 33
c93 30 205 78 249 106 11 7 35 23 54 35 35 23 51 22 51 -2z m89 -72 c2 -1 -2
-24 -9 -51 l-11 -50 -28 14 c-39 19 -101 2 -211 -57 -148 -81 -360 -154 -501
-174 -44 -7 -47 -6 -51 19 -5 23 -1 26 31 32 130 22 424 115 456 145 5 5 -5 9
-25 9 -32 1 -28 3 35 29 39 15 105 46 149 68 73 37 82 39 120 29 22 -5 43 -11
45 -13z m-2438 -334 c398 -296 800 -439 1309 -465 298 -15 666 52 942 170 61
27 91 35 103 29 36 -21 -294 -152 -497 -198 -199 -45 -342 -58 -558 -52 -339
10 -617 80 -917 230 -267 135 -528 343 -703 562 -60 75 -56 72 71 -56 75 -74
187 -173 250 -220z m324 75 c183 -109 415 -198 624 -240 69 -14 130 -31 135
-38 20 -24 95 -47 116 -36 11 6 20 15 20 20 0 15 32 11 54 -5 26 -19 80 -19
96 0 7 8 22 15 34 14 21 0 21 0 -1 -11 -13 -6 -23 -20 -23 -31 0 -19 -5 -19
-107 -13 -247 17 -509 85 -729 191 -119 57 -320 188 -394 255 -44 40 -44 40
25 -9 39 -27 106 -71 150 -97z m570 21 c126 -32 301 -55 421 -55 58 0 93 -4
89 -10 -3 -5 7 -16 22 -25 26 -15 21 -16 -97 -11 -132 6 -249 24 -365 57 -83
24 -205 69 -205 76 0 2 8 2 18 -2 11 -3 63 -17 117 -30z m1016 -234 c-16 -12
-22 -25 -19 -38 5 -18 -5 -23 -96 -43 -55 -13 -146 -29 -201 -35 -88 -9 -100
-9 -103 5 -2 8 -10 20 -19 27 -12 9 -3 13 44 18 66 7 274 48 348 69 68 19 74
19 46 -3z m695 -333 c-11 -26 -30 -41 -92 -72 -172 -84 -475 -193 -506 -181
-9 4 -42 -5 -75 -19 -66 -28 -217 -59 -373 -77 -101 -11 -326 -8 -459 8 l-55
6 43 13 c31 9 83 11 190 6 191 -9 297 0 519 44 261 51 547 152 747 263 39 22
71 40 72 40 1 1 -4 -14 -11 -31z m-736 -223 c-161 -40 -302 -56 -482 -56 -174
0 -213 5 -221 24 -3 9 59 12 242 13 172 0 285 6 376 18 71 9 131 19 134 21 2
3 14 5 25 4 12 -1 -22 -12 -74 -24z"
      />
      <path
        fill={color}
        d="M5920 3561 c0 -7 -9 -24 -19 -38 -11 -13 -17 -31 -14 -39 3 -8 -1
-14 -11 -14 -9 0 -16 -4 -16 -10 0 -5 5 -10 11 -10 5 0 15 -13 20 -30 6 -16
15 -28 19 -25 5 3 15 -3 22 -14 11 -16 22 -18 61 -14 31 4 47 10 47 20 0 30
30 72 46 66 9 -3 -6 17 -33 45 -38 40 -56 52 -79 52 -16 0 -35 5 -42 12 -9 9
-12 9 -12 -1z"
      />
      <path
        fill={color}
        d="M1993 3295 c-47 -20 -58 -48 -58 -150 0 -100 15 -138 61 -155 14 -6
127 -10 250 -10 136 0 233 -4 245 -10 13 -7 19 -21 19 -45 0 -65 -1 -65 -306
-65 l-275 0 3 -57 3 -58 310 0 c329 0 356 3 385 48 8 13 15 57 17 107 3 68 1
92 -14 120 -26 51 -52 60 -181 60 -73 0 -111 4 -107 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10 -3 -5 -40 -10 -81 -10 -43 0 -74 4 -74 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10 0 -16 -37 -12 -54 6 -19 18 -21 65 -4 82 9 9 85 12 280 12
l268 0 0 60 0 60 -302 0 c-235 -1 -310 -4 -335 -15z"
      />
      <path
        fill={color}
        d="M2900 3245 l0 -65 145 0 145 0 0 -220 0 -220 70 0 70 0 0 220 0 220
145 0 145 0 0 65 0 65 -360 0 -360 0 0 -65z"
      />
      <path
        fill={color}
        d="M3880 3088 c0 -203 2 -225 20 -264 37 -76 50 -78 398 -82 l302 -4 0
286 0 286 -70 0 -70 0 0 -221 0 -220 -206 3 c-190 3 -207 4 -220 22 -11 16
-14 63 -14 218 l0 198 -70 0 -70 0 0 -222z"
      />
      <path
        fill={color}
        d="M4890 3024 l0 -286 303 4 c349 4 362 6 407 88 24 43 25 53 25 200 0
166 -6 191 -57 236 -44 40 -78 44 -384 44 l-294 0 0 -286z m577 129 c21 -32
31 -192 14 -235 -15 -40 -61 -48 -268 -48 l-183 0 0 155 0 155 210 0 210 0 17
-27z"
      />
      <path fill={color} d="M5910 3025 l0 -285 70 0 70 0 0 285 0 285 -70 0 -70 0 0 -285z" />
      <path
        fill={color}
        d="M6414 3291 c-69 -32 -79 -65 -79 -266 0 -189 6 -213 63 -251 43 -30
134 -37 370 -32 289 6 295 12 300 273 3 161 2 172 -20 215 -37 72 -47 75 -338
78 -231 2 -259 0 -296 -17z m500 -127 c22 -21 23 -240 2 -270 -13 -17 -30 -19
-205 -22 l-191 -3 -25 25 c-24 24 -25 31 -25 133 0 162 -13 153 230 153 167 0
201 -2 214 -16z"
      />
      <path
        d="M3545 1716 c-11 -8 -21 -19 -21 -25 -1 -6 -2 -15 -3 -20 0 -5 -15
-12 -31 -16 -30 -7 -31 -8 -24 -53 3 -26 11 -58 18 -72 10 -22 17 -25 56 -21
25 1 49 9 53 17 4 8 17 14 28 14 17 0 18 3 7 20 -10 15 -10 26 0 53 14 38 15
76 4 94 -13 21 -65 26 -87 9z"
      />
    </g>
  </svg>
);

export default CStudio;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddFireTeamForm from './AddFireTeamForm';
import { sortByType } from '../AddLoadout/AddLoadout.helper';
import { isInComplete } from './AddFireTeam.helper';
import { withAuth } from '../../Auth';
import List from '../List';
import type { FireTeamType, FireTeamDataType, SnackbarType } from '../../../types';
import { useStateContext } from '../../StateProvider';
import FormLayout from '../FormLayout';

const AddFireTeam = () => {
  const [
    {
      matches,
      context: { units, fireTeams, error, filterBy },
    },
    send,
  ] = useStateContext();
  const [selectedFireTeam, setSelectedFireTeam] = React.useState<FireTeamType>();
  const sortedFireTeams = fireTeams
    ? fireTeams.sort(sortByType).filter((l: FireTeamType) => (filterBy ? l.factionKeyword === filterBy : true))
    : [];

  if (matches({ authorized: 'error' })) return <div>Something went wrong :0 {error}</div>;

  const onAddFireTeam = (fireTeam: Partial<FireTeamDataType>): void => {
    const snackbar: SnackbarType = {
      type: 'success',
      text: `${fireTeam.type} added!`,
    };
    send('ADD_FIRE_TEAM', { fireTeam, snackbar });
  };

  const onEditFireTeam = (fireTeam: Partial<FireTeamType>) => {
    const snackbar: SnackbarType = {
      type: 'warning',
      text: `${fireTeam.type} updated!`,
    };
    send('UPDATE_FIRE_TEAM', { fireTeam, snackbar });

    setSelectedFireTeam(undefined);
  };

  const onDelete = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      const snackbar: SnackbarType = {
        type: 'error',
        text: `Fire Team deleted!`,
      };
      send('DELETE_FIRE_TEAM', { id, snackbar });
    }
  };

  const onSelectFireTeam = (id: string) => {
    const selectFireTeam = fireTeams.find((ft: FireTeamType) => ft.id === id);

    if (selectFireTeam) {
      setSelectedFireTeam(selectFireTeam);
    }
  };

  if (matches({ authorized: 'editingFireTeam' }) || matches({ authorized: 'addingFireTeam' }))
    return <CircularProgress />;

  return (
    <FormLayout
      title="Add Fire Team"
      section1={
        fireTeams !== undefined ? (
          <List
            title={`Fire Teams (${sortedFireTeams.length} / ${fireTeams.length})`}
            items={sortedFireTeams as unknown as Record<string, unknown>[]}
            onItemClick={onSelectFireTeam}
            onItemDelete={onDelete}
            inComplete={isInComplete}
          />
        ) : (
          <CircularProgress />
        )
      }
      section2={
        <AddFireTeamForm
          onAddFireTeam={onAddFireTeam}
          onEditFireTeam={onEditFireTeam}
          selectedFireTeam={selectedFireTeam}
          units={units}
          filterBy={filterBy}
        />
      }
    />
  );
};

export default withAuth(AddFireTeam);

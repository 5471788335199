import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import type { LoadoutWithType } from './AddUnit.helper';
import { createLoadoutType, filterLoadouts } from './AddUnit.helper';
import type { UnitLoadoutType, LoadoutType } from '../../../types';
import { useStateContext } from '../../StateProvider';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UnitLoadouts = ({
  index,
  loadout,
  loadouts = [],
  setLoadouts,
  keywords = [],
}: {
  index: number;
  loadout?: UnitLoadoutType;
  loadouts?: Array<UnitLoadoutType>;
  setLoadouts: (values: UnitLoadoutType[]) => void;
  keywords?: string[];
}): JSX.Element => {
  const classes = useStyles();
  const [
    {
      context: { loadouts: dbLoadouts },
    },
  ] = useStateContext();

  const filteredLoadouts = keywords.length ? filterLoadouts(dbLoadouts, keywords) : dbLoadouts;

  const updateLoadout = (e: any) => {
    const id = e.target.value;
    if (!id) {
      const updatedLoadouts = loadouts.filter((_, i) => i !== index);
      setLoadouts(updatedLoadouts);

      return;
    }

    const selected = dbLoadouts.find((l: LoadoutType) => l.id === id);

    if (selected) {
      if (!loadouts.length) {
        setLoadouts([selected]);
        return;
      }

      if (loadout) {
        const updatedAddedLoadouts = loadouts.map((l) => {
          if (l.id === loadout.id) {
            return selected;
          }

          return l;
        });

        setLoadouts(updatedAddedLoadouts);
      } else {
        setLoadouts([...loadouts, selected]);
      }
    }
  };

  const loadoutMarkup = filteredLoadouts.map(createLoadoutType).map((loadout: LoadoutWithType) => (
    <MenuItem key={loadout.id} value={loadout.id}>
      {loadout.type}
    </MenuItem>
  ));

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="demo-simple-select-label">
        Loadout #{index + 1} [{`${filteredLoadouts.length} / ${loadouts.length}`}]
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={updateLoadout}
        value={loadout ? loadout.id : ''}
        fullWidth
      >
        <MenuItem value="">None</MenuItem>
        {loadoutMarkup}
      </Select>
      {loadout && (
        <FormHelperText>{loadout.updatedAt && formatDistanceInWords(new Date(loadout.updatedAt))}</FormHelperText>
      )}
    </FormControl>
  );
};

export default UnitLoadouts;

import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const LeaderCheckbox = ({
  onChange,
  selected = false,
}: {
  onChange: (value: boolean) => void;
  selected: boolean;
}): JSX.Element => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          checked={selected}
          name="weapon-select-leader-checkbox"
        />
      }
      label="Leader Weapon"
    />
  );
};

export default LeaderCheckbox;

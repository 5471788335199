import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMachine from './machine';
import { AuthProvider } from './components/Auth';
import StateContext from './components/StateProvider';
import MyTeams from './components/MyTeams';
import Header from './components/Header';
import Footer from './components/Footer';
import Admin from './components/Admin';
import { HOME, MY_TEAMS, SIGN_IN, ADMIN } from './constants/routes';
import StartPage from './components/StartPage';
import LoginForm from './components/LoginForm';
import Snackbar from './components/Snackbar';

import './index.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const NotFound = () => {
  return <p>Not Found</p>;
};

const App: React.FC = () => {
  const stateMachine: any = useMachine();
  const [current] = stateMachine;
  const classes = useStyles();

  console.log(current.value, current.context); // eslint-disable-line

  return (
    <Router>
      <AuthProvider context={current.context}>
        <StateContext.Provider value={stateMachine}>
          <div className={classes.root}>
            <CssBaseline />
            <Container maxWidth="md">
              <Header title="Kill Team Companion 2.ALPHA" />
              {current.matches('authorizing') ? (
                <div style={{ textAlign: 'center', padding: '10rem 0' }}>
                  <CircularProgress />
                </div>
              ) : (
                <Switch>
                  <Route exact path={HOME} component={() => <StartPage />} />
                  <Route exact path={MY_TEAMS} component={() => <MyTeams />} />
                  <Route path={SIGN_IN} component={() => <LoginForm />} />
                  <Route path={ADMIN} component={() => <Admin />} />
                  <Route path="*" component={NotFound} />
                </Switch>
              )}
              <Snackbar />
            </Container>
            <Footer />
          </div>
        </StateContext.Provider>
      </AuthProvider>
    </Router>
  );
};

export default App;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectLoadout from './SelectLoadout';
import LeaderCheckbox from './LeaderCheckbox';
import { useStateContext } from '../../StateProvider';
import type { LoadoutDataType, LoadoutType, WeaponType } from '../../../types';
import { filterWeapons, createLoadoutType } from './AddLoadout.helper';
import { sortByType } from '../AddWeapon/AddWeapon.helper';
import FactionSelect from '../AddWeapon/FactionSelect';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddLoadoutForm = ({
  onAdd,
  onEdit,
  selected,
  filterBy,
}: {
  onAdd: (value: LoadoutDataType) => void;
  onEdit: (value: LoadoutType) => void;
  selected: LoadoutType | undefined;
  filterBy?: string;
}): JSX.Element => {
  const [
    {
      context: { weapons: dbWeapons },
    },
  ] = useStateContext();
  const classes = useStyles();
  const [weapons, setWeapons] = React.useState<WeaponType[]>(dbWeapons);
  const [addedWeapons, setAddedWeapons] = React.useState<Array<WeaponType>>([]);
  const [factionKeyword, setFactionKeyword] = React.useState('');
  const [isLeaderType, setIsLeaderType] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState<number>();
  const [mode, setMode] = React.useState('ADD');
  const amountOfAddedWeapons = addedWeapons.length;
  const sortedWeapons = filterBy
    ? filterWeapons(weapons, filterBy, isLeaderType).sort(sortByType)
    : weapons.sort(sortByType);

  const resetForm = () => {
    setAddedWeapons([]);
    setFactionKeyword('');
    setIsLeaderType(false);
    setUpdatedAt(undefined);
    setMode('ADD');
  };

  const onResetClick = () => {
    resetForm();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const type = createLoadoutType(addedWeapons);

    if (mode === 'ADD') {
      onAdd({ weapons: addedWeapons, factionKeyword, isLeaderType, type });
    } else {
      if (selected) {
        onEdit({ ...selected, weapons: addedWeapons, factionKeyword, isLeaderType, type });
      }
    }

    resetForm();
  };

  React.useEffect(() => {
    if (selected) {
      const { weapons: loadout, factionKeyword, isLeaderType, updatedAt } = selected;
      setAddedWeapons(loadout || []);
      setFactionKeyword(factionKeyword || '');
      setIsLeaderType(!!isLeaderType);
      setUpdatedAt(updatedAt);
      setMode('EDIT');
    }
  }, [selected]);

  React.useEffect(() => {
    setWeapons(dbWeapons);
  }, [dbWeapons]);

  React.useEffect(() => {
    if (filterBy) {
      setFactionKeyword(filterBy);
    }
  }, [filterBy]);

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <Button onClick={onResetClick} fullWidth variant="contained" color="secondary">
        New
      </Button>
      <FormHelperText style={{ margin: '0.5rem 0' }}>{`${
        updatedAt ? formatDistanceInWords(new Date(updatedAt)) : ''
      }`}</FormHelperText>

      <FactionSelect onChange={setFactionKeyword} selected={factionKeyword} />
      <LeaderCheckbox onChange={setIsLeaderType} selected={isLeaderType} />

      {addedWeapons.map((addedWeapon, i) => (
        <SelectLoadout
          key={`${addedWeapon.id}-${i}`}
          index={i}
          weapons={sortedWeapons}
          addedWeapons={addedWeapons}
          setAddedWeapons={setAddedWeapons}
          addedWeapon={addedWeapon}
        />
      ))}

      <SelectLoadout
        index={amountOfAddedWeapons}
        weapons={sortedWeapons}
        addedWeapons={addedWeapons}
        setAddedWeapons={setAddedWeapons}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!addedWeapons.length}
      >
        {mode === 'ADD' ? 'Add Loadout' : 'Edit Loadout'}
      </Button>
    </form>
  );
};

export default AddLoadoutForm;

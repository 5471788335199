import React from 'react';
import { useStateContext } from './StateProvider';
import LoginForm from './LoginForm';

const LoggedIn = () => {
  return (
    <div>
      <h1>Hej!</h1>

      <p>Kul att du vilja logga in!</p>
      <p>Som du ser är det fett mycket kvar att göra, men vi får se om vi kan få till en fungerande liten app. </p>
    </div>
  );
};

const NotLoggedIn = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

const StartPage: React.FC = () => {
  const [
    {
      context: { user },
    },
  ] = useStateContext();

  if (user) {
    return <LoggedIn />;
  }

  return <NotLoggedIn />;
};

export default StartPage;

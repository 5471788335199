import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import type { UnitType, FireTeamUnitType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';

const FireTeamUnitHelperText = ({ unit }: { unit: FireTeamUnitType }) => {
  const distance = unit.updatedAt ? formatDistanceInWords(new Date(unit.updatedAt)) : '';
  return <FormHelperText>{distance}</FormHelperText>;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const FireTeamUnit = ({
  index,
  units,
  fireTeamUnitId = '',
  fireTeamUnits = [],
  setFireTeamUnits,
}: {
  index: number;
  units: Array<UnitType>;
  fireTeamUnitId?: string;
  fireTeamUnits?: Array<FireTeamUnitType>;
  setFireTeamUnits: (values: FireTeamUnitType[]) => void;
}): JSX.Element => {
  const classes = useStyles();
  const unitsMarkup = units.map((unit) => (
    <MenuItem key={unit.id} value={unit.id}>
      {unit.type}
    </MenuItem>
  ));
  const updateFireTeamUnit = (e: any): void => {
    const id = e.target.value;

    if (!id) {
      const updatedFireTeamUnits = fireTeamUnits.filter(function (item, i) {
        return i !== index;
      });

      setFireTeamUnits(updatedFireTeamUnits);

      return;
    }

    const selectedUnit = units.find((unit) => unit.id === id);
    if (selectedUnit) {
      if (index === fireTeamUnits.length) {
        // add new
        setFireTeamUnits([...fireTeamUnits, selectedUnit]);
      } else {
        const updatedFireTeamUnits = fireTeamUnits.map((fireTeamUnit, i) => {
          if (i === index) {
            return selectedUnit;
          }

          return fireTeamUnit;
        });

        setFireTeamUnits(updatedFireTeamUnits);
      }
    }
  };

  const updateFireTeamUnitFireTeamUniqueCheckbox = (e: any, unitId: string) => {
    const fireTeamUnique = !!e.target.checked;
    const selectedUnit = units.find((unit) => unit.id === unitId);

    if (selectedUnit) {
      const updatedFireTeamUnits = fireTeamUnits.map((fireTeamUnit, i) => {
        if (i === index) {
          return { ...fireTeamUnit, fireTeamUnique };
        }

        return fireTeamUnit;
      });

      setFireTeamUnits(updatedFireTeamUnits);
    }
  };

  const updateFireTeamUnitKillTeamUniqueCheckbox = (e: any, unitId: string) => {
    const killTeamUnique = !!e.target.checked;
    const selectedUnit = units.find((unit) => unit.id === unitId);

    if (selectedUnit) {
      const updatedFireTeamUnits = fireTeamUnits.map((fireTeamUnit, i) => {
        if (i === index) {
          return { ...fireTeamUnit, killTeamUnique };
        }

        return fireTeamUnit;
      });

      setFireTeamUnits(updatedFireTeamUnits);
    }
  };

  const updateFireTeamUnitRequiredCheckbox = (e: any, unitId: string) => {
    const required = !!e.target.checked;
    const selectedUnit = units.find((unit) => unit.id === unitId);

    if (selectedUnit) {
      const updatedFireTeamUnits = fireTeamUnits.map((fireTeamUnit, i) => {
        if (i === index) {
          return { ...fireTeamUnit, required };
        }

        return fireTeamUnit;
      });

      setFireTeamUnits(updatedFireTeamUnits);
    }
  };

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-label">Unit #{index + 1}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={updateFireTeamUnit}
          value={fireTeamUnitId}
          fullWidth
        >
          <MenuItem value="">None</MenuItem>
          {unitsMarkup}
        </Select>
        {fireTeamUnitId && <FireTeamUnitHelperText unit={fireTeamUnits[index]} />}
      </FormControl>

      {fireTeamUnitId && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={fireTeamUnitId ? fireTeamUnits[index].fireTeamUnique : false}
                onChange={(e) => updateFireTeamUnitFireTeamUniqueCheckbox(e, fireTeamUnitId)}
                name="fireteam-ft-unique"
                disabled={!fireTeamUnits.length}
                id={`${fireTeamUnitId}-ft-unique-id-${index}`}
              />
            }
            label="Fire Team Unique"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fireTeamUnitId ? fireTeamUnits[index].killTeamUnique : false}
                onChange={(e) => updateFireTeamUnitKillTeamUniqueCheckbox(e, fireTeamUnitId)}
                name="killteam-kt-unique"
                disabled={!fireTeamUnits.length}
                id={`${fireTeamUnitId}-kt-unique-id-${index}`}
              />
            }
            label="Kill Team Unique"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fireTeamUnitId ? fireTeamUnits[index].required : false}
                onChange={(e) => updateFireTeamUnitRequiredCheckbox(e, fireTeamUnitId)}
                name="killteam-required"
                disabled={!fireTeamUnits.length}
                id={`${fireTeamUnitId}-kt-required-id-${index}`}
              />
            }
            label="Required Unit"
          />
          <FormHelperText>For example: Sergeant in Intercessor Fire Team</FormHelperText>
        </FormGroup>
      )}
      <hr />
    </div>
  );
};

export default FireTeamUnit;

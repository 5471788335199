import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import UnitStats from './UnitStats';
import UnitAbilities from './UnitAbilities';
import UnitLoadouts from './UnitLoadouts';
import type { UnitType, UnitDataType } from '../../../types';
import UnitKeywords from './UnitKeywords';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddUnitForm = ({
  onAddUnit,
  onEditUnit,
  selectedUnit,
  unitKeywords,
}: {
  onAddUnit: (value: Partial<UnitDataType>) => void;
  onEditUnit: (value: Partial<UnitType>) => void;
  selectedUnit: UnitType | undefined;
  unitKeywords: string[];
}): JSX.Element => {
  const classes = useStyles();
  const [unit, setUnit] = React.useState<Partial<UnitDataType>>();
  const [mode, setMode] = React.useState('ADD');
  const amountOfAddedLoadouts = unit?.loadouts ? unit.loadouts.length : 0;
  const resetForm = () => {
    setUnit(undefined);
    setMode('ADD');
  };

  const onResetClick = (e: any) => {
    resetForm();
    setUnit({ ...unit, keywords: unitKeywords });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (mode === 'ADD') {
      if (unit) {
        onAddUnit(unit);
      }
    } else {
      if (selectedUnit) {
        if (unit) {
          onEditUnit(unit);
        }
      }
    }

    resetForm();
  };

  React.useEffect(() => {
    if (selectedUnit) {
      setUnit(selectedUnit);
      setMode('EDIT');
    }
  }, [selectedUnit]);

  React.useEffect(() => {
    if (unitKeywords.length) {
      setUnit({ ...unit, keywords: unitKeywords });
    }
  }, [unitKeywords]);

  return (
    <form onSubmit={onSubmit}>
      <Button onClick={onResetClick} fullWidth variant="contained" color="secondary">
        New
      </Button>
      <FormHelperText style={{ margin: '0.5rem 0' }}>{`${
        selectedUnit && selectedUnit.updatedAt ? formatDistanceInWords(new Date(selectedUnit.updatedAt)) : ''
      }`}</FormHelperText>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="unit-type"
        label="Unit Type"
        name="unit-type"
        onChange={(e) => setUnit({ ...unit, type: e.target.value })}
        value={unit?.type || ''}
        helperText="Example: Plague Marine Warrior, Intercessor Sergeant"
      />
      <UnitKeywords
        key={`keywords-${unit && unit.keywords && unit.keywords.length ? unit.keywords.toString() : 'none'}`}
        keywords={unit?.keywords || []}
        setKeywords={(keywords) => setUnit({ ...unit, keywords })}
      />
      <UnitStats stats={unit?.stats} setStats={(stats) => setUnit({ ...unit, stats })} />
      <UnitAbilities abilities={unit?.abilities} setAbilities={(abilities) => setUnit({ ...unit, abilities })} />
      <Typography variant="h6">Loadouts</Typography>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-label">Maximum amount of Loadouts</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={unit?.maxLoadouts ? unit?.maxLoadouts.toString() : ''}
          onChange={(e) => setUnit({ ...unit, maxLoadouts: Number(e.target.value) })}
          fullWidth
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
        </Select>
        <FormHelperText>Ex: Tyranid Warrior (0 is unlimited)</FormHelperText>
      </FormControl>
      {unit?.loadouts &&
        unit.loadouts.map((loadout, i) => (
          <UnitLoadouts
            key={`${loadout.id}-${i}`}
            index={i}
            loadouts={unit.loadouts}
            setLoadouts={(loadouts) => setUnit({ ...unit, loadouts })}
            loadout={loadout}
            keywords={unit.keywords}
          />
        ))}
      <UnitLoadouts
        index={amountOfAddedLoadouts}
        loadouts={unit?.loadouts}
        setLoadouts={(loadouts) => setUnit({ ...unit, loadouts })}
        keywords={unit?.keywords}
      />
      <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
        {mode === 'ADD' ? 'Add' : 'Edit'} Unit
      </Button>
    </form>
  );
};

export default AddUnitForm;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { useStateContext } from './StateProvider';
import { MY_TEAMS, HOME, ADMIN } from '../constants/routes';

const sections = [
  { title: 'My Teams', url: MY_TEAMS },
  { title: 'Admin', url: ADMIN },
  { title: 'About', url: HOME },
];

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const NavigationLoggedIn: React.FC = () => {
  const classes = useStyles();
  return (
    <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
      {sections.map((section) => (
        <Link
          component={NavLink}
          to={section.url}
          color="inherit"
          noWrap
          key={section.title}
          variant="body2"
          className={classes.toolbarLink}
        >
          {section.title}
        </Link>
      ))}
    </Toolbar>
  );
};

const Navigation: React.FC = () => {
  const [
    {
      context: { user },
    },
  ] = useStateContext();
  if (user) {
    return <NavigationLoggedIn />;
  }
  return null;
};

export default Navigation;

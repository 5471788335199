import React, { Dispatch, SetStateAction } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import WeaponStats from './WeaponStats';
import type { WeaponProfileType, WeaponStatsType } from '../../../types';

const WeaponProfile = ({
  index,
  profile,
  profiles,
  setProfiles,
}: {
  index: number;
  profile?: WeaponProfileType;
  profiles: Array<WeaponProfileType>;
  setProfiles: (value: WeaponProfileType[]) => void;
}): JSX.Element => {
  const [type, setType] = React.useState<string>('');
  const [stats, setStats] = React.useState<WeaponStatsType>();
  const styleConfig = { border: `${!profile ? '1px solid rgba(60, 179, 113, 1)' : 'none'}`, padding: '0.5rem' };
  const onClickHandler = () => {
    const updatedProfile = { type, stats };

    if (!profiles.length) {
      setProfiles([updatedProfile]);
      setType('');
      setStats(undefined);
      return;
    }

    if (!profile) {
      setProfiles([...profiles, updatedProfile]);
      setType('');
      setStats(undefined);
      return;
    }

    const updatedProfiles = profiles.map((p) => {
      if (p.type === profile.type) {
        return updatedProfile;
      }

      return p;
    });

    setProfiles(updatedProfiles);
    setType('');
    setStats(undefined);
  };

  React.useEffect(() => {
    if (profile?.type) {
      setType(profile.type);
    }

    if (profile?.stats) {
      setStats(profile.stats);
    }
  }, [profile]);

  return (
    <div>
      <FormGroup style={styleConfig}>
        <InputLabel id="demo-simple-select-label"> {!profile ? 'New' : `#${index + 1} `} Profile</InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id={`${index}-type`}
          label="Type"
          name="type"
          onChange={(e) => setType(e.target.value)}
          value={type}
          helperText="Example: Chainsword, Heavy bolt pistol, Standard, Supercharge"
        />
        <WeaponStats stats={stats} setStats={setStats} />
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: '1.5rem 0.5rem' }}
          disabled={!type}
          onClick={onClickHandler}
        >
          {!profile ? 'New' : 'Edit'} Profile
        </Button>
      </FormGroup>
    </div>
  );
};

export default WeaponProfile;

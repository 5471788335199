import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import type { WeaponType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SelectLoadout = ({
  index,
  weapons,
  addedWeapons,
  setAddedWeapons,
  addedWeapon,
}: {
  index: number;
  weapons: Array<WeaponType>;
  addedWeapons: Array<WeaponType>;
  setAddedWeapons: Dispatch<SetStateAction<Array<WeaponType>>>;
  addedWeapon?: WeaponType;
}): JSX.Element => {
  const classes = useStyles();
  const weaponsMarkup = weapons.map((weapon) => (
    <MenuItem key={weapon.id} value={weapon.id}>
      {weapon.type}
    </MenuItem>
  ));

  const updateWeapon = (e: any) => {
    const id = e.target.value;

    if (!id) {
      const updatedWeapons = addedWeapons.filter((_, i) => i !== index);
      setAddedWeapons(updatedWeapons);

      return;
    }

    const selected = weapons.find((weapon) => weapon.id === id);

    if (selected) {
      if (!addedWeapons.length) {
        setAddedWeapons([selected]);
        return;
      }

      if (addedWeapon) {
        const updatedAddedWeapons = addedWeapons.map((ad) => {
          if (ad.id === addedWeapon.id) {
            return selected;
          }

          return ad;
        });

        setAddedWeapons(updatedAddedWeapons);
      } else {
        setAddedWeapons([...addedWeapons, selected]);
      }
    }
  };

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="demo-simple-select-label">Weapon #{index + 1}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={updateWeapon}
        value={addedWeapon ? addedWeapon.id : ''}
        fullWidth
      >
        <MenuItem value="">None</MenuItem>
        {weaponsMarkup}
      </Select>
      {addedWeapon && (
        <FormHelperText>
          {addedWeapon.updatedAt && formatDistanceInWords(new Date(addedWeapon.updatedAt))}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectLoadout;

import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectFireTeamUnit from './SelectFireTeamUnit';

import type { FactionFireTeamType, FireTeamUnitType } from '../../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
}));

const SelectFireTeamUnits = ({
  factionFireTeamUnits,
  selectedFireTeam,
  selectedFireTeams,
  setFireTeams,
  fireTeamIndex,
  mode,
}: {
  factionFireTeamUnits: Array<FireTeamUnitType>;
  selectedFireTeam: FactionFireTeamType;
  selectedFireTeams: Array<FactionFireTeamType>;
  setFireTeams: Dispatch<SetStateAction<Array<FactionFireTeamType>>>;
  fireTeamIndex: number;
  mode: string;
}): JSX.Element => {
  const classes = useStyles();
  const [selectedUnits, setSelectedUnits] = React.useState<Array<FireTeamUnitType>>([]);

  React.useEffect(() => {
    if (selectedUnits.length) {
      const updatedFireTeams = selectedFireTeams.map((ft, i) => {
        if (i === fireTeamIndex) {
          return { ...selectedFireTeam, units: selectedUnits };
        }

        return ft;
      });
      setFireTeams(updatedFireTeams);
    }
  }, [selectedUnits]);

  React.useEffect(() => {
    // only when editing
    if (selectedFireTeam && mode === 'EDIT') {
      const { units } = selectedFireTeam;
      setSelectedUnits(units);
    } else {
      setSelectedUnits([]);
    }
  }, []);

  const updateFireTeamUnits = (unitIndex: number, unit?: FireTeamUnitType) => {
    if (unit) {
      if (!selectedUnits.length) {
        setSelectedUnits([unit]);
      } else if (selectedUnits.length === unitIndex) {
        setSelectedUnits([...selectedUnits, unit]);
      } else {
        const updatedUnits = selectedUnits.map((u, i) => {
          if (i === unitIndex) return unit;
          return u;
        });

        setSelectedUnits(updatedUnits);
      }
    } else {
      // delete
      const updatedUnits = selectedUnits.filter((_, i) => i !== unitIndex);
      setSelectedUnits(updatedUnits);
    }
  };

  return (
    <div>
      <FormControl required error={false} component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          id="fireTeamName"
          label="Fire Team Name"
          onChange={(e) => {
            const updatedFireTeams = selectedFireTeams.map((ft, i) => {
              if (fireTeamIndex === i) return { ...selectedFireTeam, name: e.target.value };

              return ft;
            });
            setFireTeams(updatedFireTeams);
          }}
          value={selectedFireTeam.name}
          placeholder={selectedFireTeam.type}
          fullWidth
        />
        <FormHelperText id="my-helper-text">(Optional)</FormHelperText>
      </FormControl>
      <FormControl required error={false} component="fieldset" className={classes.formControl} fullWidth>
        <FormLabel component="legend">Select {selectedFireTeam.amount} units</FormLabel>
        {selectedUnits.map((selectedUnit, i) => (
          <SelectFireTeamUnit
            key={`${selectedUnit.id}-${i}`}
            index={i}
            factionFireTeamUnits={factionFireTeamUnits}
            selectedUnit={selectedUnit}
            updateFireTeamUnits={updateFireTeamUnits}
            fireTeamId={selectedFireTeam.id}
          />
        ))}

        <SelectFireTeamUnit
          index={selectedUnits.length}
          factionFireTeamUnits={factionFireTeamUnits}
          updateFireTeamUnits={updateFireTeamUnits}
          fireTeamId={selectedFireTeam.id}
        />
      </FormControl>
    </div>
  );
};

export default SelectFireTeamUnits;

export function authUser(firebase: any): any {
  return firebase.onAuthListener();
}

export function loginUser(firebase: any, { email, password }: any): any {
  return Promise.all([firebase.signInWithEmailAndPassword({ email, password })]).then((data) => {
    return data[0].user;
  });
}

export function setUserData({ data }: any): any {
  const { displayName, email, photoURL, uid } = data;
  return { displayName, email, photoURL, uid };
}

import { WeaponType, LoadoutType } from '../../../types';

export const filterWeapons = (weapons: WeaponType[], keyword: string, isLeaderType = false): WeaponType[] => {
  if (isLeaderType) {
    return weapons.filter((w) => w.factionKeyword === keyword).filter((w) => w.isLeaderType === isLeaderType);
  }

  return weapons.filter((w) => w.factionKeyword === keyword).filter((w) => !w.isLeaderType);
};

export const sortByType = (a: LoadoutType, b: LoadoutType): number => {
  const typeA = a.type || 0;
  const typeB = b.type || 0;

  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const isInComplete = (item: Partial<LoadoutType>): string => {
  const factionKeyword = item.factionKeyword || null;
  const isLeaderType = item.isLeaderType ? 'Leader' : null;

  const filteredArray = [factionKeyword, isLeaderType].filter((a) => a !== null);

  return filteredArray.join(', ');
};

export const createLoadoutType = (weapons: WeaponType[]): string => {
  return weapons
    .map((w) => w.type)
    .sort()
    .join(', ');
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { Divider } from '@material-ui/core';
import type { FactionFireTeamType, FireTeamType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';

const FactionFireTeamUnitHelperText = ({ fireTeam }: { fireTeam: FactionFireTeamType }) => {
  const distance = fireTeam.updatedAt ? formatDistanceInWords(new Date(fireTeam.updatedAt)) : '';
  return <FormHelperText>{distance}</FormHelperText>;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const FactionFireTeam = ({
  index,
  fireTeams,
  factionFireTeamId = '',
  factionFireTeams = [],
  setFactionFireTeams,
}: {
  index: number;
  fireTeams: Array<FireTeamType>;
  factionFireTeamId?: string;
  factionFireTeams?: Array<FactionFireTeamType>;
  setFactionFireTeams: (values: FactionFireTeamType[]) => void;
}): JSX.Element => {
  const classes = useStyles();
  const fireTeamsMarkup = fireTeams.map((fireTeam) => (
    <MenuItem key={fireTeam.id} value={fireTeam.id}>
      {fireTeam.type}
    </MenuItem>
  ));

  const updateFactionFireTeam = (e: any): void => {
    const id = e.target.value;
    if (!id) {
      const updatedFireTeamUnits = factionFireTeams.filter(function (_, i) {
        return i !== index;
      });

      setFactionFireTeams(updatedFireTeamUnits);

      return;
    }

    const selectedFireTeam = fireTeams.find((fireTeam) => fireTeam.id === id);

    if (selectedFireTeam) {
      if (index === factionFireTeams.length) {
        // add new
        setFactionFireTeams([...factionFireTeams, selectedFireTeam]);
      } else {
        const updatedFactionFireTeams = factionFireTeams.map((factionFireTeam, i) => {
          if (i === index) {
            return selectedFireTeam;
          }

          return factionFireTeam;
        });

        console.log({ id, selectedFireTeam, updatedFactionFireTeams, factionFireTeams });
        setFactionFireTeams(updatedFactionFireTeams);
      }
    }
  };

  const updateFactionFireTeamSelect = (e: any, selectId: string) => {
    const limit = e.target.value && Number(e.target.value);
    const id = selectId.split('-')[0];
    const selectedFireTeam = fireTeams.find((fireTeam) => fireTeam.id === id);

    if (selectedFireTeam) {
      const updatedFactionFireTeams = factionFireTeams.map((factionFireTeam, i) => {
        if (factionFireTeam.id === selectedFireTeam.id) {
          return { ...selectedFireTeam, limit };
        }

        return factionFireTeam;
      });

      setFactionFireTeams(updatedFactionFireTeams);
    }
  };

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-label">Fire Team #{index + 1}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={updateFactionFireTeam}
          value={factionFireTeamId}
          fullWidth
        >
          <MenuItem value={''}>None</MenuItem>
          {fireTeamsMarkup}
        </Select>
        {factionFireTeamId && <FactionFireTeamUnitHelperText fireTeam={factionFireTeams[index]} />}
      </FormControl>

      {factionFireTeamId && (
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="demo-simple-select-label">Limited amount of Fire Team in Faction</InputLabel>
          <Select
            name="faction-max-fireteams"
            labelId="demo-simple-select-label"
            onChange={(e) => updateFactionFireTeamSelect(e, `${factionFireTeamId}-unique-id-${index}`)}
            value={
              factionFireTeams[index] && factionFireTeams[index].limit ? factionFireTeams[index].limit?.toString() : ''
            }
            fullWidth
          >
            <MenuItem value={'-'}>No Limit</MenuItem>
            <MenuItem value={'1'}>1</MenuItem>
            <MenuItem value={'2'}>2</MenuItem>
          </Select>
        </FormControl>
      )}
      <Divider style={{ margin: '1rem 0' }} />
    </div>
  );
};

export default FactionFireTeam;

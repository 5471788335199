import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Button';

const UnitKeywords = ({
  keywords,
  setKeywords,
}: {
  keywords: Array<string>;
  setKeywords: (values: string[]) => void;
}): JSX.Element => {
  const [keyword, setKeyword] = React.useState('');

  const handleDelete = (word: string) => {
    const updatedKeywords = keywords.filter((k) => k !== word);
    setKeywords(updatedKeywords);
  };

  const keywordsMarkup = !!keywords.length && (
    <Container fullWidth>
      {keywords.map((k, i) => (
        <Chip key={i} size="small" label={k} onDelete={() => handleDelete(k)} />
      ))}
    </Container>
  );

  return (
    <div>
      <Typography variant="h6">Keywords</Typography>
      {keywordsMarkup}
      <TextField
        variant="outlined"
        margin="normal"
        id="keyword"
        label="Keyword"
        name="keyword"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        helperText="Example: Imperium, Chaos, Leader"
      />
      <Button
        variant="contained"
        color="secondary"
        style={{ margin: '1.5rem 0.5rem' }}
        disabled={!keyword}
        onClick={() => {
          setKeywords([...keywords, keyword.toUpperCase()]);
          setKeyword('');
        }}
      >
        Add Keyword
      </Button>
    </div>
  );
};

export default UnitKeywords;

import React, { Dispatch, SetStateAction } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import type { WeaponStatsType } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    textField: {},
  }),
);

const statKeys = ['a', 'bs/ws', 'd', 'sr', 'i'];

const WeaponStats = ({
  stats,
  setStats,
}: {
  stats?: WeaponStatsType;
  setStats: (values: WeaponStatsType) => void;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">Stats</Typography>
      <div className={classes.root}>
        {statKeys.map((key) => {
          return (
            <TextField
              key={key}
              label={`${key.toUpperCase()}`}
              id={`${key}-stat`}
              variant="filled"
              size="small"
              onChange={(e) => setStats({ ...stats, [key]: e.target.value })}
              value={(stats && stats[key] && stats[key].toString()) || ''}
              className={classes.textField}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WeaponStats;

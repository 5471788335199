import type { WeaponType } from '../../../types';

export const isInComplete = (item: Partial<WeaponType>): string => {
  const factionKeyword = item.factionKeyword || null;
  const isLeaderType = item.isLeaderType ? 'Leader' : null;

  const filteredArray = [factionKeyword, isLeaderType].filter((a) => a !== null);

  return filteredArray.join(', ');
};

export const sortByType = (a: WeaponType, b: WeaponType): number => {
  const typeA = a.type || 0;
  const typeB = b.type || 0;

  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  // names must be equal
  return 0;
};

import React, { Dispatch, SetStateAction } from 'react';
import Typography from '@material-ui/core/Typography';
import type { UnitStatsType } from '../../../types';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    textField: {},
  }),
);

const statKeys = ['m', 'apl', 'ga', 'df', 'sv', 'w'];

const UnitStats = ({
  stats,
  setStats,
}: {
  stats?: UnitStatsType;
  setStats: (values: UnitStatsType) => void;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">Stats</Typography>
      <div className={classes.root}>
        {statKeys.map((key) => {
          return (
            <TextField
              key={key}
              label={`${key.toUpperCase()}`}
              id={`${key}-stat`}
              variant="filled"
              size="small"
              onChange={(e) => setStats({ ...stats, [key]: Number(e.target.value) })}
              value={(stats && stats[key] && stats[key].toString()) || '0'}
              className={classes.textField}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UnitStats;

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import FireTeamUnitList from './FireTeamUnitList';
import { FactionFireTeamType } from '../../../types';
import { colors } from '../../../constants';

const FireTeamListItem = ({ fireTeam }: { fireTeam: FactionFireTeamType }): JSX.Element => {
  const fireTeamTitle = fireTeam.name ? fireTeam.name : `${fireTeam.type} Fire Team`;
  const fireTeamSubTitle = fireTeam.name ? `${fireTeam.type} Fire Team` : '';
  return (
    <ListItem style={{ display: 'block' }}>
      <Typography variant="h5">{fireTeamTitle} </Typography>
      <Typography variant="subtitle2" style={{ color: colors.red }}>
        {fireTeamSubTitle}
      </Typography>
      <FireTeamUnitList units={fireTeam.units} />
    </ListItem>
  );
};

export default FireTeamListItem;

import { createContext, useContext } from 'react';

const StateContext = createContext({} as any);

export default StateContext;

// export default ({ state = null, children }: { state: any, children: any}) => {
//   return <StateContext.Provider value={state}>{children}</StateContext.Provider>;
// };

export const useStateContext = () => {
  return useContext(StateContext);
};

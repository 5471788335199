import React from 'react';
import type { IconType } from './icon.types';

const Tyranids = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M926.5,571c-19.1,0-34.5-18.4-34.5-41s15.4-41,34.5-41c9,0,17.2,4.1,23.3,10.8c1.9,2.1,5.4,0.8,5.4-2.1
	c-0.3-32.2-3.1-62.5-8.3-91.1c-0.5-2.8-4.4-3.5-5.7-1c-4,7.5-10.6,13.3-19,15.8c-17.8,5.3-37-6.4-42.8-26.1
	c-5.8-19.7,3.9-40,21.7-45.2c9.6-2.8,19.5-0.8,27.6,4.8c2.4,1.7,5.6-0.7,4.7-3.5c-12-38.2-28.8-72.5-49.7-102.9
	c-2-2.9-6.3-1.9-7,1.5c-1.3,6.5-4.4,12.6-9.3,17.5c-13.9,13.9-37.2,13.2-51.9-1.6c-14.8-14.8-15.5-38.1-1.6-51.9
	c6.7-6.7,15.5-10,24.6-10c3.5,0,5.2-4.1,2.8-6.6c-28.4-28.4-61.3-51.9-98-70.7c-3.2-1.7-6.7,1.7-5.3,5c3.7,8.7,4.1,18.3,0.1,27
	c-8.1,17.6-30.9,24.5-51,15.3c-20.1-9.2-29.8-31-21.7-48.6c3.6-7.9,10.2-13.7,18.2-16.7c3.3-1.3,3.2-6.1-0.2-7.3
	C625.7,81.8,561.5,72,493.1,72c0,0-0.1,0-0.1,0C233.1,87.9,56.6,304.1,30.4,497.3c-0.2,1.4,0.9,2.7,2.4,2.6
	c39.7-0.9,118.9-2.5,158-7.4c4.3-0.5,5.1-6.4,1.1-8.1c-15.1-6.5-25-13.3-27.4-21c-0.5-1.5,0.7-3,2.3-3c11.9,0.3,48.9-9.8,67.4-23.3
	c1.5-1.1,1.2-3.4-0.5-4.1c-7.4-3-24.1-6.7-20.8-10.1c21.5-4.8,49-10.9,74.9-29c1.6-1.1,1.2-3.7-0.8-4.2
	c-17.8-4.5-31.2-9.6-36.3-16.4c-1.1-1.5-0.2-3.5,1.6-3.7c26.3-2.5,56.5-4.1,79.2-17.8c1.7-1.1,1.4-3.7-0.6-4.2
	c-17.4-5.1-32-11.2-30.4-23.8c0.1-1.1,1-1.9,2.1-2c79.3-8.5,118,20,117.3,84.5c0,1-0.6,1.9-1.6,2.2c-19.8,6.7-40.1,4.1-63,1.9
	c-1.9-0.2-3.2,1.8-2.3,3.5c6.3,11.1,11.3,18.2,14,22.8c0.5,0.8,0.4,1.8-0.1,2.6c-10.3,14-23.3,9.5-43-1.5c-1.5-0.8-3.4,0.2-3.5,1.9
	c-0.3,8.9,4,14.6,9,19.9c0.8,0.9,0.9,2.2,0.1,3.1c-10.7,12.6-30.2,11.7-51.4,7.6c-3.5-0.7-6,3.4-3.7,6.2
	c5.4,6.6,10.2,11.8,14.1,14.6c1.4,1,1.3,3.2-0.3,4c-11,5.4-26.9,6.1-43.6,5.6c-3.9-0.1-5.3,5.1-1.9,6.9
	c35.5,18.9,91.6,20.7,140.2,14.5c0.6-0.1,1.1-0.3,1.4-0.8c44.3-49.4,99.4-83.8,163.1-106.5c1.9-0.7,2.1-3.3,0.3-4.3
	c-14.1-7.3-26.2-19.2-35.9-35.1c-0.9-1.5,0.2-3.5,2-3.5c128.1-0.3,195.3,70.2,200,213.8c0,0.2,0,0.4,0,0.5
	C689,721.9,586.8,802.1,465.8,819c0,0,0,0,0,0c-140.6,9.8-261.2-53.4-369.4-178.5c-1.6-1.9-4.6-0.3-4,2.1
	c27.5,105.7,92.8,198.3,201.3,254.9c1.9,1,4.3-0.3,4.5-2.4c0.3-3.6,1.6-7,4-9.8c7.2-8.6,21.7-8.3,32.2,0.6
	c10.6,8.9,13.3,23.1,6,31.6c-0.2,0.3-0.5,0.5-0.7,0.8c16.4,6.3,33.5,12,51.6,16.8c2.4,0.6,4.6-1.7,3.7-4c-1.2-3.3-1.6-6.8-1-10.4
	c2.2-11.7,15-19.2,28.5-16.7c13.5,2.5,22.7,14.1,20.5,25.9c-0.8,4.1-2.8,7.7-5.7,10.5c-1.8,1.8-0.9,4.8,1.6,5.3
	c12.1,2.2,24.5,4,37.2,5.5c0.1,0,0.1,0,0.2,0c16,0,31.8-0.6,47.4-1.7c2.8-0.2,3.9-3.7,1.7-5.5c-5.7-4.7-9.3-11.4-9.3-18.8
	c0-14.4,13.4-26,30-26s30,11.6,30,26c0,5-1.7,9.7-4.5,13.7c-1.6,2.2,0.2,5.3,3,4.9c31.5-4.9,61.7-12.2,90.5-22
	c2.7-0.9,2.8-4.8,0.1-5.8c-6.1-2.3-11.4-6.5-14.8-12.4c-8.4-14.6-2.1-34,14-43.3c16.2-9.3,36.1-5.1,44.5,9.5
	c5.1,8.9,4.8,19.5,0.1,28.6c-1.3,2.6,1.4,5.4,4.1,4.2c35.8-16.7,68.7-37.6,97.9-62.8c2-1.7,1-5.1-1.7-5.4
	c-6.7-0.6-13.3-3.3-18.9-7.9c-14.7-12.3-16.4-34.3-4-49.1c12.5-14.8,34.5-16.8,49.1-4.5c7.1,6,11.2,14.3,12.1,23
	c0.3,2.7,3.6,3.8,5.4,1.7c20.4-23.8,38.2-50.4,53.1-79.7c1.2-2.4-1.1-5.2-3.7-4.3c-7.9,2.6-16.8,2.6-25.4-0.5
	c-19.3-7.1-29.6-27.5-22.9-45.6c6.7-18.1,27.7-27.1,47-20c9.8,3.6,17.3,10.7,21.4,19.1c1.2,2.5,4.8,2.3,5.7-0.3
	c10.9-30.9,18.9-64.2,23.8-100c-4.1-2.1-1.1-0.6-5.1-2.7C941,568.1,934.1,571,926.5,571z M165.8,312.8c-3.9,2.8-8.8-1.7-6.4-5.8
	c16.4-27.6,46.6-65.5,83.6-88c13.8-2.3,23.7-0.9,27,7C256.4,244,205.7,283.9,165.8,312.8z"
    />
  </svg>
);

export default Tyranids;

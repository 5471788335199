import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { format } from 'date-fns';
import { FireTeamType, UserTeamType } from '../../types';
import Icon from '../Icon';
import { colors } from '../../constants';

const DisplaySecondary = ({ factionName, fireTeams }: { factionName: string; fireTeams: Array<FireTeamType> }) => {
  const fireTeamString = fireTeams
    .map(({ type }) => {
      return type;
    })
    .join(', ');
  return (
    <span>
      {factionName} with {fireTeamString} Fire Teams
    </span>
  );
};

const TeamListItem = ({
  team,
  onClick,
  selectedProjectId = '',
  onItemDelete,
  onItemView,
}: {
  team: UserTeamType;
  onClick: (event: any, id: string) => void;
  onItemDelete?: (id: string) => void;
  onItemView?: (team: UserTeamType) => void;
  selectedProjectId?: string;
}): JSX.Element => {
  const {
    faction: { type: factionName },
    fireTeams,
    name,
  } = team;
  return (
    <ListItem button selected={selectedProjectId === team.id} onClick={(event) => onClick(event, team.id)}>
      <ListItemAvatar>
        <Avatar style={{ background: colors.red }}>
          <Icon name={factionName} className="team-item-icon" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        // secondary={`created ${format(new Date(team.createdAt.seconds * 1000), 'yyyy-MM-dd')}`}
        secondary={<DisplaySecondary factionName={factionName} fireTeams={fireTeams} />}
      />

      <ListItemSecondaryAction>
        {onItemView && (
          <IconButton edge="end" aria-label="view" onClick={() => onItemView(team)}>
            <ArtTrackIcon />
          </IconButton>
        )}

        {onItemDelete && (
          <IconButton edge="end" aria-label="delete" onClick={() => onItemDelete(team.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default TeamListItem;

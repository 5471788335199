import React from 'react';
import type { IconType } from './icon.types';

const DeathGuard = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="arrows"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 969.8 931.3"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path
        d="M749.9,395.1l101.7-57.5l20.6,65.9l97.6-261.9l-223,0.8l24.6,50.8l-63.6,46c0-1.4,0.1-2.9,0.1-4.3
  C707.9,105.2,602.8,0,473,0C356.7,0,260.2,84.5,241.5,195.5l-52.6-40.4L227,121L0,81.3l77,325.4l26.1-85.7l100,85.7l0.4,0.3
  C106.8,422.8,33,506.8,33,608c0,112.5,91.2,203.7,203.7,203.7c75.2,0,140.9-40.8,176.2-101.5l5.4,89.4l-33.3-6.3l103.8,138
  l106.4-148.4l-33.3,11.9c0,0,1.8-58.7,3.8-77.7c36.9,49.4,95.8,81.4,162.2,81.4c111.7,0,202.3-90.6,202.3-202.3
  C930.2,491.9,851.3,406.1,749.9,395.1z M236.6,739.1c-72.4,0-131.1-58.7-131.1-131.1s58.7-131.1,131.1-131.1s131.2,58.6,131.2,131
  S309.1,739.1,236.6,739.1z M471.5,393.9c-90,0-162.9-72.9-162.9-162.9S381.5,68.1,471.5,68.1S634.4,141,634.4,231
  S561.5,393.9,471.5,393.9z M727.8,737.1c-77.8,0-140.9-63.1-140.9-140.9s63.2-140.9,141-140.9s140.8,63.1,140.8,140.9
  S805.6,737.1,727.8,737.1z"
      />
      <path
        d="M318.7,542.6c-14.8-43.3-58.7-50.1-77.7-51c0,0-1.6-0.1-4.4-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-2.8,0-4.4,0.1-4.4,0.1
  c-18.9,0.9-62.8,7.7-77.6,51c-12.7,37,11.3,78.7,6.5,84.3c-19.5,22.6,7,58.4,10.6,61.3c6.5,5.3,27.3-5.5,24,0.1
  c-2.5,4.2-4.8,36.2-4.8,36.2h6.4l3.1-11.1l5.5-0.1l1.1,11.2h11.2l1.1-10.7l3.7-0.5l0.5,11.2h9.1L233,713h7.4l0.4,11.5h9.1l0.5-11.2
  l3.7,0.5l1.1,10.7h11.2l1.1-11.2l5.5,0.1l3.1,11.1h6.4c0,0-2.3-32-4.8-36.2c-3.3-5.6,17.5,5.2,24-0.1c3.6-3,30.1-38.7,10.6-61.3
  C307.4,621.2,331.4,579.5,318.7,542.6z M224.6,650.2c-2.5,2.9-6.2,6.2-11.2,9c-12,6.7-23.8,5.8-28.6,5.1c-0.3,0-0.6-0.1-0.9-0.3
  c-10.5-6.6-14.5-17-11.4-23.4c0.5-1,2.2-4.2,7.7-6.2c0.3-0.1,0.6-0.1,1-0.1c6.6,0.6,14.8,1.9,23.9,4.8c7.4,2.3,13.6,5.1,18.8,7.8
  C225.2,647.4,225.5,649.1,224.6,650.2z M249.6,689.3c0,0.7-1,1-1.4,0.5c-1.1-1.4-2.7-3.1-4.8-4.6c-2.2-1.6-4.3-2.6-6-3.2
  c-1.6,0.6-3.8,1.6-6,3.2c-2.1,1.5-3.6,3.2-4.8,4.6c-0.5,0.6-1.4,0.3-1.4-0.5c-0.4-5.5-0.1-16.6,8.1-24.8c1.1-1.1,2.4-2,4.1-2.9
  c1.6,0.9,3,1.8,4.1,2.9C249.7,672.7,250,683.9,249.6,689.3z M289.7,664.1c-0.3,0.2-0.6,0.3-0.9,0.3c-4.8,0.7-16.6,1.6-28.6-5.1
  c-5-2.8-8.6-6.2-11.2-9c-1-1.1-0.7-2.8,0.6-3.5c5.1-2.7,11.4-5.5,18.8-7.8c9.1-2.9,17.4-4.2,23.9-4.8c0.3,0,0.6,0,1,0.1
  c5.5,2,7.2,5.3,7.7,6.2C304.1,647.1,300.2,657.5,289.7,664.1z"
      />
      <path
        d="M812.3,528.9c-15.3-44.6-60.4-51.5-79.9-52.4c0,0-1.7-0.1-4.5-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-2.9,0-4.5,0.1-4.5,0.1
  c-19.5,0.9-64.6,7.9-79.9,52.4c-13,38,11.6,81,6.6,86.8c-20.1,23.2,7.2,60.1,10.9,63.1c6.7,5.5,28.1-5.6,24.7,0.1
  c-2.5,4.4-4.9,37.3-4.9,37.3h6.6l3.2-11.5l5.7-0.1l1.1,11.5h11.5l1.1-11l3.8-0.5l0.5,11.5h9.4l0.4-11.8h7.6l0.4,11.8h9.4l0.5-11.5
  l3.8,0.5l1.1,11h11.5l1.1-11.5l5.7,0.1l3.2,11.5h6.6c0,0-2.4-32.9-4.9-37.3c-3.4-5.7,18,5.3,24.7-0.1c3.7-3,31-39.8,10.9-63.1
  C800.6,609.8,825.3,566.9,812.3,528.9z M715.4,639.7c-2.6,2.9-6.4,6.4-11.5,9.3c-12.3,6.9-24.5,6-29.4,5.3c-0.3,0-0.7-0.1-0.9-0.3
  c-10.8-6.8-14.9-17.5-11.7-24.1c0.5-1,2.3-4.4,7.9-6.4c0.3-0.1,0.7-0.1,1-0.1c6.8,0.6,15.3,2,24.6,4.9c7.6,2.4,14,5.2,19.3,8
  C716.1,636.8,716.4,638.5,715.4,639.7z M741.1,679.9c0,0.8-1,1.1-1.5,0.5c-1.2-1.5-2.8-3.2-4.9-4.8c-2.3-1.7-4.5-2.7-6.2-3.3
  c-1.7,0.6-3.9,1.7-6.2,3.3c-2.1,1.6-3.7,3.3-4.9,4.8c-0.5,0.6-1.4,0.3-1.5-0.5c-0.4-5.6-0.1-17.1,8.3-25.5c1.1-1.1,2.5-2,4.2-2.9
  c1.6,0.9,3.1,1.9,4.2,2.9C741.3,662.8,741.5,674.3,741.1,679.9z M782.4,653.9c-0.3,0.2-0.6,0.3-0.9,0.3c-4.9,0.7-17.1,1.7-29.4-5.3
  c-5.1-2.9-8.9-6.4-11.5-9.3c-1-1.1-0.7-2.9,0.7-3.6c5.3-2.8,11.8-5.6,19.3-8c9.4-2.9,17.9-4.3,24.6-4.9c0.3,0,0.7,0,1,0.1
  c5.6,2.1,7.4,5.4,7.9,6.4C797.2,636.4,793.1,647.1,782.4,653.9z"
      />
      <path
        d="M578.6,140.6C559.2,84.1,502,75.3,477.3,74.1c0,0-2.1-0.1-5.7-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-3.6,0-5.7,0.1-5.7,0.1
  c-24.7,1.2-81.9,10-101.3,66.5c-16.5,48.2,14.8,102.7,8.4,110c-25.4,29.4,9.2,76.1,13.8,80c8.5,6.9,35.6-7.1,31.3,0.1
  c-3.2,5.5-6.2,47.3-6.2,47.3h8.4l4-14.5l7.2-0.1l1.4,14.6h14.6l1.4-13.9l4.9-0.7l0.7,14.6h11.9l0.6-15h9.6l0.6,15h11.9l0.7-14.6
  l4.9,0.7l1.4,13.9h14.6l1.4-14.6l7.2,0.1l4,14.5h8.4c0,0-3-41.7-6.2-47.3c-4.3-7.3,22.8,6.8,31.3-0.1c4.7-3.9,39.3-50.5,13.8-80
  C563.8,243.3,595.1,188.8,578.6,140.6z M455.8,281.1c-3.3,3.7-8.1,8.1-14.6,11.8c-15.6,8.8-31.1,7.5-37.3,6.7
  c-0.4,0-0.8-0.2-1.2-0.4c-13.7-8.7-18.9-22.1-14.8-30.6c0.6-1.3,2.9-5.5,10.1-8.1c0.4-0.1,0.8-0.2,1.2-0.2
  c8.7,0.8,19.4,2.5,31.2,6.2c9.6,3,17.8,6.6,24.5,10.1C456.6,277.5,457,279.7,455.8,281.1z M488.4,332.1c0,1-1.2,1.3-1.8,0.6
  c-1.5-1.9-3.5-4-6.2-6c-2.9-2.1-5.7-3.5-7.8-4.2c-2.1,0.8-4.9,2.1-7.8,4.2c-2.7,2-4.7,4.1-6.2,6c-0.6,0.7-1.8,0.4-1.8-0.6
  c-0.5-7.1-0.1-21.7,10.6-32.3c1.4-1.4,3.2-2.6,5.3-3.7c2.1,1.2,3.9,2.3,5.3,3.7C488.6,310.4,488.9,325,488.4,332.1z M540.7,299.1
  c-0.4,0.2-0.7,0.4-1.2,0.4c-6.3,0.9-21.7,2.1-37.3-6.7c-6.5-3.6-11.3-8.1-14.6-11.8c-1.2-1.4-0.9-3.6,0.8-4.5
  c6.7-3.5,14.9-7.1,24.5-10.1c11.9-3.7,22.6-5.4,31.2-6.2c0.4,0,0.8,0,1.2,0.2c7.1,2.6,9.4,6.9,10.1,8.1
  C559.6,277,554.4,290.5,540.7,299.1z"
      />
    </g>
  </svg>
);

export default DeathGuard;

import { MachineConfig, send } from 'xstate';
import { FactionType, AbilityType, UserTeamType, WeaponType, LoadoutType, SnackbarType, UserType } from '../types';

interface AuthContext {
  user?: UserType | null;
  userTeams?: Array<UserTeamType> | null;
  factions?: Array<FactionType> | null;
  abilities?: Array<AbilityType> | null;
  weapons?: Array<WeaponType> | null;
  loadouts?: Array<LoadoutType> | null;
  snackbar?: SnackbarType | null;
  addTeamModalVisible: boolean;
  adminTabIndex: number;
  filterBy: string;
}

type AuthEvents =
  | { type: 'SET_TAB_INDEX' }
  | { type: 'CLOSE_SNACKBAR' }
  | { type: 'ADD_UNIT' }
  | { type: 'DELETE_UNIT' }
  | { type: 'EDIT_UNIT' }
  | { type: 'ADD_FIRE_TEAM' }
  | { type: 'UPDATE_FIRE_TEAM' }
  | { type: 'DELETE_FIRE_TEAM' }
  | { type: 'LOGIN' }
  | { type: 'LOGOUT' }
  | { type: 'OPEN_ADD_TEAM_MODAL' }
  | { type: 'CLOSE_ADD_TEAM_MODAL' }
  | { type: 'ADD_FACTION' }
  | { type: 'EDIT_FACTION' }
  | { type: 'DELETE_FACTION' }
  | { type: 'ADD_USER_TEAM' }
  | { type: 'GET_USER_TEAMS' }
  | { type: 'EDIT_USER_TEAM' }
  | { type: 'DELETE_USER_TEAM' }
  | { type: 'ADD_USER_TEAM' }
  | { type: 'ADD_ABILITY' }
  | { type: 'EDIT_ABILITY' }
  | { type: 'DELETE_ABILITY' }
  | { type: 'ADD_WEAPON' }
  | { type: 'EDIT_WEAPON' }
  | { type: 'DELETE_WEAPON' }
  | { type: 'ADD_LOADOUT' }
  | { type: 'EDIT_LOADOUT' }
  | { type: 'DELETE_LOADOUT' }
  | { type: 'ADMIN_FILTER_BY' };

const stateChart: MachineConfig<AuthContext, any, AuthEvents> = {
  id: 'authMachine',
  initial: 'authorizing',
  context: {
    user: null,
    userTeams: null,
    factions: null,
    abilities: null,
    weapons: null,
    loadouts: null,
    snackbar: {
      type: 'info',
      text: '',
      isVisible: false,
    },
    addTeamModalVisible: false,
    adminTabIndex: 0,
    filterBy: '',
  },
  states: {
    authorizing: {
      invoke: {
        id: 'authServer',
        src: 'authServer',
        onDone: [
          {
            target: 'authorized',
            cond: (_: AuthContext, event: { data: boolean }): boolean => event.data,
            actions: 'authUser',
          },
          {
            target: 'unAuthorized',
          },
        ],
        onError: {
          target: 'error',
          actions: 'setError',
        },
      },
    },
    loggingIn: {
      invoke: [
        {
          src: 'loginUser',
          onDone: {
            target: 'authorized',
            cond: (_: AuthContext, event: { data: boolean }): boolean => event.data,
            actions: 'setUser',
          },
          onError: {
            target: 'unAuthorized',
            actions: 'setError',
          },
        },
        {
          src: 'authListener',
        },
      ],
    },
    loggingOut: {
      invoke: {
        src: 'logOut',
        onDone: {
          target: 'unAuthorized',
          actions: ['clearContext'],
        },
        onError: {
          target: 'error',
          actions: 'setError',
        },
      },
    },
    authorized: {
      entry: send('GET_USER_TEAMS'),
      initial: 'idle',
      states: {
        idle: {},
        loadingUserTeams: {
          invoke: {
            src: 'loadUserTeams',
            onDone: {
              target: 'loadingFactions',
              actions: 'setUserTeams',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        addingUserTeam: {
          invoke: {
            src: 'addUserTeam',
            onDone: {
              target: 'idle',
              actions: 'setUserTeam',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingUserTeam: {
          invoke: {
            src: 'updateUserTeam',
            onDone: {
              target: 'idle',
              actions: 'updateUserTeam',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingUserTeam: {
          invoke: {
            src: 'deleteUserTeam',
            onDone: {
              target: 'idle',
              actions: 'deleteUserTeam',
            },
          },
        },
        loadingFactions: {
          invoke: {
            src: 'loadFactions',
            onDone: {
              target: 'loadingUnits',
              actions: 'setFactions',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        addingFaction: {
          invoke: {
            src: 'addFaction',
            onDone: {
              target: 'idle',
              actions: 'setFaction',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingFaction: {
          invoke: {
            src: 'updateFaction',
            onDone: {
              target: 'idle',
              actions: 'updateFaction',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingFaction: {
          invoke: {
            src: 'deleteFaction',
            onDone: {
              target: 'idle',
              actions: 'deleteFaction',
            },
          },
        },
        loadingUnits: {
          invoke: {
            src: 'loadUnits',
            onDone: {
              target: 'loadingFireTeams',
              actions: 'setUnits',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        addingUnit: {
          invoke: {
            src: 'addUnit',
            onDone: {
              target: 'idle',
              actions: 'setUnit',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingUnit: {
          invoke: {
            src: 'deleteUnit',
            onDone: {
              target: 'idle',
              actions: 'deleteUnit',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingUnit: {
          invoke: {
            src: 'updateUnit',
            onDone: {
              target: 'idle',
              actions: 'updateUnit',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        loadingFireTeams: {
          invoke: {
            src: 'loadFireTeams',
            onDone: {
              target: 'loadingAbilities',
              actions: 'setFireTeams',
            },
          },
        },
        addingFireTeam: {
          invoke: {
            src: 'addFireTeam',
            onDone: {
              target: 'idle',
              actions: 'setFireTeam',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingFireTeam: {
          invoke: {
            src: 'updateFireTeam',
            onDone: {
              target: 'idle',
              actions: 'updateFireTeam',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingFireTeam: {
          invoke: {
            src: 'deleteFireTeam',
            onDone: {
              target: 'idle',
              actions: 'deleteFireTeam',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        loadingAbilities: {
          invoke: {
            src: 'loadAbilities',
            onDone: {
              target: 'loadingWeapons',
              actions: 'setAbilities',
            },
          },
        },
        addingAbility: {
          invoke: {
            src: 'addAbility',
            onDone: {
              target: 'idle',
              actions: 'setAbility',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingAbility: {
          invoke: {
            src: 'updateAbility',
            onDone: {
              target: 'idle',
              actions: 'updateAbility',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingAbility: {
          invoke: {
            src: 'deleteAbility',
            onDone: {
              target: 'idle',
              actions: 'deleteAbility',
            },
          },
        },
        loadingWeapons: {
          invoke: {
            src: 'loadWeapons',
            onDone: {
              target: 'loadingLoadouts',
              actions: 'setWeapons',
            },
          },
        },
        addingWeapon: {
          invoke: {
            src: 'addWeapon',
            onDone: {
              target: 'idle',
              actions: 'setWeapon',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingWeapon: {
          invoke: {
            src: 'updateWeapon',
            onDone: {
              target: 'idle',
              actions: 'updateWeapon',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingWeapon: {
          invoke: {
            src: 'deleteWeapon',
            onDone: {
              target: 'idle',
              actions: 'deleteWeapon',
            },
          },
        },
        loadingLoadouts: {
          invoke: {
            src: 'loadLoadouts',
            onDone: {
              target: 'idle',
              actions: 'setLoadouts',
            },
          },
        },
        addingLoadout: {
          invoke: {
            src: 'addLoadout',
            onDone: {
              target: 'idle',
              actions: 'setLoadout',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        editingLoadout: {
          invoke: {
            src: 'updateLoadout',
            onDone: {
              target: 'idle',
              actions: 'updateLoadout',
            },
            onError: {
              target: 'error',
              actions: 'setError',
            },
          },
        },
        deletingLoadout: {
          invoke: {
            src: 'deleteLoadout',
            onDone: {
              target: 'idle',
              actions: 'deleteLoadout',
            },
          },
        },
        error: {},
      },
      on: {
        GET_USER_TEAMS: [{ target: '.loadingUserTeams', cond: 'userTeamsNotLoaded' }, { target: '.loadingFactions' }],
        ADD_USER_TEAM: { target: '.addingUserTeam', actions: ['toggleSnackbar', 'toggleAddTeamModal'] },
        EDIT_USER_TEAM: {
          target: '.editingUserTeam',
          actions: ['toggleSnackbar'],
        },
        DELETE_USER_TEAM: [{ target: '.deletingUserTeam', actions: 'toggleSnackbar' }],
        LOGOUT: 'loggingOut',
        OPEN_ADD_TEAM_MODAL: [
          { target: '.loadingFactions', cond: 'factionsNotLoaded', actions: 'toggleAddTeamModal' },
          { target: '.idle', actions: 'toggleAddTeamModal' },
        ],
        CLOSE_ADD_TEAM_MODAL: { actions: 'toggleAddTeamModal' },
        ADD_UNIT: { target: '.addingUnit', actions: 'toggleSnackbar' },
        DELETE_UNIT: { target: '.deletingUnit', actions: 'toggleSnackbar' },
        EDIT_UNIT: { target: '.editingUnit', actions: 'toggleSnackbar' },
        ADD_FIRE_TEAM: { target: '.addingFireTeam', actions: 'toggleSnackbar' },
        UPDATE_FIRE_TEAM: { target: '.editingFireTeam', actions: 'toggleSnackbar' },
        DELETE_FIRE_TEAM: { target: '.deletingFireTeam', actions: 'toggleSnackbar' },
        CLOSE_SNACKBAR: { actions: 'toggleSnackbar' },
        SET_TAB_INDEX: { actions: 'setTabIndex' },
        ADD_FACTION: { target: '.addingFaction', actions: 'toggleSnackbar' },
        EDIT_FACTION: { target: '.editingFaction', actions: 'toggleSnackbar' },
        DELETE_FACTION: { target: '.deletingFaction', actions: 'toggleSnackbar' },
        ADD_ABILITY: { target: '.addingAbility', actions: 'toggleSnackbar' },
        EDIT_ABILITY: { target: '.editingAbility', actions: 'toggleSnackbar' },
        DELETE_ABILITY: { target: '.deletingAbility', actions: 'toggleSnackbar' },
        ADD_WEAPON: { target: '.addingWeapon', actions: 'toggleSnackbar' },
        EDIT_WEAPON: { target: '.editingWeapon', actions: 'toggleSnackbar' },
        DELETE_WEAPON: { target: '.deletingWeapon', actions: 'toggleSnackbar' },
        ADD_LOADOUT: { target: '.addingLoadout', actions: 'toggleSnackbar' },
        EDIT_LOADOUT: { target: '.editingLoadout', actions: 'toggleSnackbar' },
        DELETE_LOADOUT: { target: '.deletingLoadout', actions: 'toggleSnackbar' },
        ADMIN_FILTER_BY: { actions: 'setFilterBy' },
      },
    },
    unAuthorized: {
      on: {
        LOGIN: 'loggingIn',
      },
    },
    error: {
      // type: 'final',
    },
  },
};

export default stateChart;

import React from 'react';
import { useStateContext } from './StateProvider';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { SIGN_IN } from '../constants/routes';

const LogOutButton: React.FC = () => {
  const history = useHistory();
  const [, send] = useStateContext();

  const logOut = (e: any) => {
    e.preventDefault();
    send('LOGOUT');
    history.push('/');
  };

  return (
    <Button variant="outlined" size="small" onClick={(e) => logOut(e)}>
      Log out
    </Button>
  );
};

const SignUpButton: React.FC = () => {
  const history = useHistory();

  const [
    {
      context: { user },
    },
  ] = useStateContext();

  if (user) {
    return <LogOutButton />;
  }

  const goToLoginPage = () => {
    history.push(SIGN_IN);
  };

  return (
    <Button variant="outlined" size="small" onClick={goToLoginPage}>
      Login
    </Button>
  );
};

export default SignUpButton;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import type { AbilityDataType, AbilityType } from '../../../types';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddFactionForm = ({
  onAdd,
  onEdit,
  selected,
}: {
  onAdd: (value: AbilityDataType) => void;
  onEdit: (value: AbilityType) => void;
  selected: AbilityType | undefined;
}): JSX.Element => {
  const classes = useStyles();
  const [type, setType] = React.useState('');
  const [descr, setDescr] = React.useState('');
  const [mode, setMode] = React.useState('ADD');

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (mode === 'ADD') {
      onAdd({ type, descr });
    } else {
      if (selected) {
        onEdit({ ...selected, type, descr });
      }
    }

    setType('');
    setDescr('');
    setMode('ADD');
  };

  React.useEffect(() => {
    if (selected) {
      const { type, descr } = selected;
      setType(type);
      setDescr(descr || '');
      setMode('EDIT');
    }
  }, [selected]);

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Type"
        name="type"
        onChange={(e) => setType(e.target.value)}
        value={type}
        helperText="Example: Disgustingly Resilient"
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="descr"
        label="Description"
        name="descr"
        autoComplete="descr"
        onChange={(e) => setDescr(e.target.value)}
        value={descr}
        helperText="Example: Each time this operative..."
        multiline
        maxRows={4}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!type.length}
      >
        {mode === 'ADD' ? 'Add Ability' : 'Edit Ability'}
      </Button>
    </form>
  );
};

export default AddFactionForm;

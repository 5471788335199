import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DisplayUserTeam from './DisplayUserTeam/DisplayUserTeam';
import TeamListItem from './TeamListItem';
import { useStateContext } from '../StateProvider';
import { UserTeamType, SnackbarType } from '../../types';
import Modal from '../Modal';
import AddTeamForm from './AddTeamForm';
import { colors } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const TeamsList: React.FC = () => {
  const [
    {
      context: { userTeams },
    },
    send,
  ] = useStateContext();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedUserTeam, setSelectedUserTeam] = React.useState<UserTeamType>();
  const [mode, setMode] = React.useState('EDIT');

  const onDelete = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      const snackbar: SnackbarType = {
        type: 'error',
        text: `Team deleted!`,
      };
      send('DELETE_USER_TEAM', { id, snackbar });
    }
  };

  const onView = (t: UserTeamType) => {
    setIsOpen(true);
    setSelectedUserTeam(t);
    setMode('VIEW');
  };

  return (
    <div>
      <List className={classes.root}>
        {userTeams && userTeams.length ? (
          userTeams.map((team: UserTeamType) => (
            <TeamListItem
              key={team.id}
              team={team}
              onClick={() => {
                setIsOpen(true);
                setSelectedUserTeam(team);
                setMode('EDIT');
              }}
              onItemDelete={onDelete}
              onItemView={onView}
            />
          ))
        ) : (
          <Alert severity="info">No teams yet</Alert>
        )}
      </List>
      {selectedUserTeam && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          {mode === 'EDIT' ? (
            <Grid container spacing={3} alignItems="center" justify="center">
              <Grid item xs={9}>
                <Typography variant="h5">{selectedUserTeam.name}</Typography>
                <Typography variant="subtitle2" style={{ color: colors.red }}>
                  {selectedUserTeam.faction.type}
                </Typography>
              </Grid>
              <Grid container item xs={3} alignItems="center">
                <Button variant="contained" color="secondary" onClick={() => setMode('VIEW')}>
                  View
                </Button>
              </Grid>
              <Grid item xs={12}>
                <AddTeamForm selected={selectedUserTeam} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Typography variant="h5">{selectedUserTeam.name}</Typography>
                <Typography variant="subtitle2" style={{ color: colors.red }}>
                  {selectedUserTeam.faction.type}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="secondary" onClick={() => setMode('EDIT')}>
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DisplayUserTeam team={selectedUserTeam} />
              </Grid>
            </Grid>
          )}
        </Modal>
      )}
    </div>
  );
};

export default TeamsList;

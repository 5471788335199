import type { LoadoutType, UnitType } from '../../../types';
import { factionArray } from '../../../constants/factions';

export const isUnitInComplete = (item: Partial<UnitType>): string => {
  if (item.keywords) {
    const factionKeyword = factionArray.find(
      (k) => item.keywords && !!item.keywords.find((f) => f === k.toUpperCase()),
    );

    const leaderKeyword = item.keywords.includes('LEADER') ? 'Leader' : null;

    const filteredArray = [factionKeyword || null, leaderKeyword].filter((a) => a !== null);

    return filteredArray.join(', ');
  }

  return '';
};

export interface LoadoutWithType extends LoadoutType {
  type: string;
}

export const createLoadoutType = (loadout: LoadoutType): LoadoutWithType => {
  const type = loadout.weapons
    .map((l) => {
      return l.type;
    })
    .join(', ');

  return {
    ...loadout,
    type,
  };
};

const filterLeaderType = (loadout: LoadoutType): boolean => {
  return !!loadout.isLeaderType;
};

const hasFactionKeyword = (factionKeyword: string) => {
  return function (loadout: LoadoutType) {
    return loadout.factionKeyword?.toUpperCase() === factionKeyword;
  };
};

export const filterLoadouts = (loadouts: LoadoutType[], keywords: string[]): LoadoutType[] => {
  const hasLeaderKeyword = keywords.includes('LEADER');
  const factionKeyword = keywords.find((k) => !!factionArray.find((f) => f.toUpperCase() === k));

  if (hasLeaderKeyword && factionKeyword) {
    return loadouts.filter(hasFactionKeyword(factionKeyword)).filter(filterLeaderType);
  }

  if (hasLeaderKeyword) {
    return loadouts.filter(filterLeaderType);
  }

  if (factionKeyword) {
    return loadouts.filter(hasFactionKeyword(factionKeyword)).filter((l) => !l.isLeaderType);
  }

  return loadouts;
};

export const sortByType = (a: UnitType, b: UnitType): number => {
  const typeA = a.type || 0;
  const typeB = b.type || 0;

  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  // names must be equal
  return 0;
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import type { FireTeamUnitType, LoadoutType } from '../../../types';

export interface LoadoutWithType extends LoadoutType {
  type: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    // marginLeft: theme.spacing(3),
    // margin: '0 1.5rem',
  },
}));

const setLoadoutValue = (selectedUnit: FireTeamUnitType): string => {
  if (selectedUnit && selectedUnit.loadouts) {
    const filteredLoadouts = selectedUnit.loadouts.filter((l) => l.checked);

    if (filteredLoadouts.length) {
      return filteredLoadouts[0].id;
    }
  }
  return '';
};

const SelectFireTeamUnit = ({
  index,
  selectedUnit,
  factionFireTeamUnits,

  updateFireTeamUnits,
  fireTeamId,
}: {
  index: number;
  selectedUnit?: FireTeamUnitType;
  factionFireTeamUnits: Array<FireTeamUnitType>;

  updateFireTeamUnits: (unitIndex: number, unit?: FireTeamUnitType) => void;
  fireTeamId: string;
}): JSX.Element => {
  const classes = useStyles();
  const updateOnChange = (id?: string, unit?: FireTeamUnitType) => {
    if (id && unit) {
      updateFireTeamUnits(index, unit);
    } else if (id) {
      const selectedUnit = factionFireTeamUnits.find((u) => u.id === id);
      if (selectedUnit) {
        updateFireTeamUnits(index, selectedUnit);
      }
    } else {
      updateFireTeamUnits(index);
    }
  };

  const unitsMarkup = factionFireTeamUnits.map((unit) => (
    <MenuItem key={unit.id} value={unit.id}>
      {unit.type}
    </MenuItem>
  ));

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id={`select-unit-label-${index}-${fireTeamId}`}>Unit #{index + 1}</InputLabel>

        <Select
          labelId={`select-unit-label-${index}-${fireTeamId}`}
          id={`select-unit-${index}-${fireTeamId}`}
          onChange={(e) => updateOnChange(e.target.value as string)}
          value={selectedUnit ? selectedUnit.id : ''}
          fullWidth
        >
          <MenuItem value="">None</MenuItem>
          {unitsMarkup}
        </Select>
      </FormControl>
      {selectedUnit && (
        <div>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="unit-name"
              label="Unit Name"
              name="unit-name"
              onChange={(e) => updateOnChange(selectedUnit.id, { ...selectedUnit, name: e.target.value })}
              value={selectedUnit ? selectedUnit.name : ''}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id={`select-loadout-label-${index}-${fireTeamId}`}>
              Loadout (Max {selectedUnit.maxLoadouts} loadout(s))
            </InputLabel>
            <Select
              labelId={`select-loadout-label-${index}-${fireTeamId}`}
              id={`select-loadout-${index}-${fireTeamId}`}
              onChange={(e) =>
                updateOnChange(selectedUnit.id, {
                  ...selectedUnit,
                  loadouts: selectedUnit.loadouts?.map((l) => {
                    if (l.id === e.target.value) {
                      return { ...l, checked: true };
                    }

                    return { ...l, checked: false };
                  }),
                })
              }
              value={setLoadoutValue(selectedUnit)}
              fullWidth
            >
              <MenuItem value="">None</MenuItem>
              {selectedUnit.loadouts?.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default SelectFireTeamUnit;

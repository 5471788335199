import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { FireTeamUnitType } from '../../../types';
import { colors } from '../../../constants';
import { Skull } from '../../../assets/icons';

const FireTeamUnitListItem = ({
  unit: { name, type, stats, loadouts, abilities },
}: {
  unit: FireTeamUnitType;
}): JSX.Element => {
  const unitAbilities = abilities?.map((a) => a.type).join(', ');
  const unitLoadouts = loadouts
    ?.filter((l) => l.checked)
    .map((l, i) => <span key={`loadout-unit-${l.id}-${i}`}>{l.type}</span>);
  const unitStats =
    stats && `M: ${stats.m}, APL: ${stats.apl}, GA: ${stats.ga}, DF: ${stats.df}, SV: ${stats.sv}+, W: ${stats.w}`;

  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ background: colors.highlight110 }} title={unitStats}>
            <Skull color={colors.white} width="25px" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            <React.Fragment>
              <span style={{ color: colors.red, fontWeight: 'bold' }}>{type}</span>
              {unitAbilities && unitAbilities.length ? ` • ${unitAbilities}` : null}
              <br />
              {unitLoadouts}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
};

export default FireTeamUnitListItem;
/*
{name} <br />
{type} <br />
{keywords?.join(', ')}
  
  */

/* eslint-disable */
import { createMachine, assign } from 'xstate';
import { useMachine } from '@xstate/react';

import { loginUser, setUserData } from './services';
import stateChart from './stateChart';
import { useFirebase } from '../firebase';
import {
  FactionType,
  UnitType,
  UserTeamType,
  FireTeamType,
  AbilityType,
  WeaponType,
  LoadoutType,
  SnackbarType,
} from '../types';

const cleanData = (data: unknown) => {
  const stringified = JSON.stringify(data);
  return JSON.parse(stringified);
};

const useClientMachine = (): any => {
  const firebase = useFirebase();

  const options = {
    actions: {
      authUser: assign({ user: (_, event: any) => event.data }),
      setUser: assign({ user: (_, event) => setUserData(event) }),
      clearContext: assign({
        user: null,
        userTeams: null,
        factions: null,
        addTeamModalVisible: false,
        units: false,
        fireTeams: false,
      }),
      setError: assign({ error: (_, event: any) => event.data }),
      setUserTeams: assign({ userTeams: (_, event: any): Array<UserTeamType> => event.data }),
      setUserTeam: assign({
        userTeams: ({ userTeams }, event: any): Array<UserTeamType> => [...userTeams, event.data],
      }),
      updateUserTeam: assign({
        userTeams: ({ userTeams }, event: any): Array<UserTeamType> =>
          userTeams.map((userTeam: UserTeamType) => {
            if (userTeam.id === event.data.id) {
              return event.data;
            }

            return userTeam;
          }),
      }),
      deleteUserTeam: assign({
        userTeams: ({ userTeams }, event: any): Array<UserTeamType> =>
          userTeams.filter((userTeam: UserTeamType) => userTeam.id !== event.data),
      }),
      setFactions: assign({ factions: (_, event: any): Array<FactionType> => event.data }),
      setFaction: assign({ factions: ({ factions }, event: any): Array<FactionType> => [...factions, event.data] }),
      updateFaction: assign({
        factions: ({ factions }, event: any): Array<FactionType> =>
          factions.map((faction: LoadoutType) => {
            if (faction.id === event.data.id) {
              return event.data;
            }

            return faction;
          }),
      }),
      deleteFaction: assign({
        factions: ({ factions }, event: any): Array<FactionType> =>
          factions.filter((faction: FactionType) => faction.id !== event.data),
      }),
      setUnits: assign({ units: (_, event: any): Array<UnitType> => event.data }),
      setUnit: assign({ units: ({ units }, event: any): Array<UnitType> => [...units, event.data] }),
      deleteUnit: assign({
        units: ({ units }, event: any): Array<UnitType> => units.filter((unit: UnitType) => unit.id !== event.data),
      }),
      updateUnit: assign({
        units: ({ units }, event: any): Array<UnitType> =>
          units.map((unit: UnitType) => {
            if (unit.id === event.data.id) {
              return event.data;
            }

            return unit;
          }),
      }),
      setFireTeams: assign({ fireTeams: (_, event: any): Array<FireTeamType> => event.data }),
      setFireTeam: assign({
        fireTeams: ({ fireTeams }, event: any): Array<FireTeamType> => [...fireTeams, event.data],
      }),
      updateFireTeam: assign({
        fireTeams: ({ fireTeams }, event: any): Array<FireTeamType> =>
          fireTeams.map((fireTeam: FireTeamType) => {
            if (fireTeam.id === event.data.id) {
              return event.data;
            }

            return fireTeam;
          }),
      }),
      deleteFireTeam: assign({
        fireTeams: ({ fireTeams }, event: any): Array<FireTeamType> =>
          fireTeams.filter((fireTeam: FireTeamType) => fireTeam.id !== event.data),
      }),
      toggleAddTeamModal: assign({
        addTeamModalVisible: ({ addTeamModalVisible }) => !addTeamModalVisible,
      }),
      toggleSnackbar: assign({
        snackbar: ({ snackbar: ctxSnackbar }: any, event: any): SnackbarType => {
          const { snackbar }: { snackbar: SnackbarType } = event;
          return {
            ...ctxSnackbar,
            ...snackbar,
            isVisible: !ctxSnackbar.isVisible,
          };
        },
      }),
      setTabIndex: assign({ adminTabIndex: (_: any, event: any) => event.tabIndex }),
      setAbilities: assign({
        abilities: (_: any, event: any): Array<AbilityType> => event.data,
      }),
      setAbility: assign({
        abilities: ({ abilities }, event: any): Array<AbilityType> => [...abilities, event.data],
      }),
      updateAbility: assign({
        abilities: ({ abilities }, event: any): Array<AbilityType> =>
          abilities.map((ability: AbilityType) => {
            if (ability.id === event.data.id) {
              return event.data;
            }

            return ability;
          }),
      }),
      deleteAbility: assign({
        abilities: ({ abilities }, event: any): Array<AbilityType> =>
          abilities.filter((ability: AbilityType) => ability.id !== event.data),
      }),
      setWeapons: assign({
        weapons: (_: any, event: any): Array<WeaponType> => event.data,
      }),
      setWeapon: assign({
        weapons: ({ weapons }, event: any): Array<WeaponType> => [...weapons, event.data],
      }),
      updateWeapon: assign({
        weapons: ({ weapons }, event: any): Array<WeaponType> =>
          weapons.map((weapon: WeaponType) => {
            if (weapon.id === event.data.id) {
              return event.data;
            }

            return weapon;
          }),
      }),
      deleteWeapon: assign({
        weapons: ({ weapons }, event: any): Array<WeaponType> =>
          weapons.filter((weapon: WeaponType) => weapon.id !== event.data),
      }),
      setLoadouts: assign({
        loadouts: (_: any, event: any): Array<LoadoutType> => event.data,
      }),
      setLoadout: assign({
        loadouts: ({ loadouts }, event: any): Array<LoadoutType> => [...loadouts, event.data],
      }),
      updateLoadout: assign({
        loadouts: ({ loadouts }, event: any): Array<LoadoutType> =>
          loadouts.map((loadout: LoadoutType) => {
            if (loadout.id === event.data.id) {
              return event.data;
            }

            return loadout;
          }),
      }),
      deleteLoadout: assign({
        loadouts: ({ loadouts }, event: any): Array<LoadoutType> =>
          loadouts.filter((loadout: LoadoutType) => loadout.id !== event.data),
      }),
      setFilterBy: assign({
        filterBy: (_: any, event: any): string => event.filterBy,
      }),
    },
    services: {
      authServer: firebase.onAuthListener(),
      loginUser: (_: any, event: any) => loginUser(firebase, event),
      logOut: () => Promise.all([firebase.signOut()]),
      loadUserTeams: ({ user: { uid } }: any) => firebase.getUserTeams(uid),
      addUserTeam: ({ user: { uid } }: any, { userTeam }: { userTeam: UserTeamType }) =>
        firebase.addUserTeam(uid, cleanData(userTeam)),
      updateUserTeam: (_: any, { userTeam }: { userTeam: UserTeamType }) =>
        firebase.updateUserTeam(cleanData(userTeam)),
      deleteUserTeam: (_: any, { id }: { id: string }) => firebase.deleteUserTeam(id),
      loadFactions: () => firebase.getFactions(),
      addFaction: (_: any, { faction }: { faction: FactionType }) => firebase.addFaction(cleanData(faction)),
      updateFaction: (_: any, { faction }: { faction: FactionType }) => firebase.updateFaction(cleanData(faction)),
      deleteFaction: (_: any, { id }: { id: string }) => firebase.deleteFaction(id),
      loadUnits: () => firebase.getUnits(),
      addUnit: (_: any, { unit }: { unit: UnitType }) => firebase.addUnit(cleanData(unit)),
      deleteUnit: (_: any, { id }: { id: string }) => firebase.deleteUnit(id),
      updateUnit: (_: any, { unit }: { unit: UnitType }) => firebase.updateUnit(cleanData(unit)),
      loadFireTeams: () => firebase.getFireTeams(),
      addFireTeam: (_: any, { fireTeam }: { fireTeam: FireTeamType }) => firebase.addFireTeam(cleanData(fireTeam)),
      updateFireTeam: (_: any, { fireTeam }: { fireTeam: FireTeamType }) =>
        firebase.updateFireTeam(cleanData(fireTeam)),
      deleteFireTeam: (_: any, { id }: { id: string }) => firebase.deleteFireTeam(id),
      loadAbilities: () => firebase.getAbilities(),
      addAbility: (_: any, { ability }: { ability: AbilityType }) => firebase.addAbility(cleanData(ability)),
      updateAbility: (_: any, { ability }: { ability: AbilityType }) => firebase.updateAbility(cleanData(ability)),
      deleteAbility: (_: any, { id }: { id: string }) => firebase.deleteAbility(id),
      loadWeapons: () => firebase.getWeapons(),
      addWeapon: (_: any, { weapon }: { weapon: WeaponType }) => firebase.addWeapon(cleanData(weapon)),
      updateWeapon: (_: any, { weapon }: { weapon: WeaponType }) => firebase.updateWeapon(cleanData(weapon)),
      deleteWeapon: (_: any, { id }: { id: string }) => firebase.deleteWeapon(id),
      loadLoadouts: () => firebase.getLoadouts(),
      addLoadout: (_: any, { loadout }: { loadout: LoadoutType }) => firebase.addLoadout(cleanData(loadout)),
      updateLoadout: (_: any, { loadout }: { loadout: LoadoutType }) => firebase.updateLoadout(cleanData(loadout)),
      deleteLoadout: (_: any, { id }: { id: string }) => firebase.deleteLoadout(id),
    },
    guards: {
      userTeamsNotLoaded: (ctx: any) => !ctx?.userTeams,
      factionsNotLoaded: (ctx: any) => !ctx?.factions,
      unitsNotLoaded: (ctx: any) => !ctx?.units,
      fireTeamsNotLoaded: (ctx: any) => !ctx?.fireTeams,
    },
  };
  // @ts-ignore
  return useMachine(createMachine(stateChart, options));
};

export default useClientMachine;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FireTeamUnit from './FireTeamUnit';
import FactionSelect from '../AddWeapon/FactionSelect';
import type { FireTeamType, UnitType, FireTeamDataType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddFireTeamForm = ({
  onAddFireTeam,
  onEditFireTeam,
  selectedFireTeam,
  units,
  filterBy,
}: {
  onAddFireTeam: (value: Partial<FireTeamDataType>) => void;
  onEditFireTeam: (value: Partial<FireTeamType>) => void;
  selectedFireTeam?: FireTeamType;
  units: Array<UnitType>;
  filterBy?: string;
}): JSX.Element => {
  const classes = useStyles();
  const [fireTeam, setFireTeam] = React.useState<Partial<FireTeamDataType>>();
  const [mode, setMode] = React.useState('ADD');
  const fireTeamUnitsCount = fireTeam && fireTeam.units ? fireTeam.units.length : 0;

  const resetForm = () => {
    setFireTeam(undefined);
    setMode('ADD');
  };

  const onResetClick = (e: any) => {
    resetForm();
  };
  const onSubmit = (e: any) => {
    e.preventDefault();

    if (mode === 'ADD') {
      if (fireTeam) {
        onAddFireTeam(fireTeam);
      }
    } else {
      if (fireTeam) {
        onEditFireTeam(fireTeam);
      }
    }

    resetForm();
  };

  React.useEffect(() => {
    if (selectedFireTeam) {
      setFireTeam(selectedFireTeam);
      setMode('EDIT');
    }
  }, [selectedFireTeam]);

  React.useEffect(() => {
    if (filterBy) {
      setFireTeam({ ...fireTeam, factionKeyword: filterBy });
    }
  }, [filterBy]);

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <Button onClick={onResetClick} fullWidth variant="contained" color="secondary">
        New
      </Button>
      <FormHelperText style={{ margin: '0.5rem 0' }}>{`${
        selectedFireTeam && selectedFireTeam.updatedAt
          ? formatDistanceInWords(new Date(selectedFireTeam.updatedAt))
          : ''
      }`}</FormHelperText>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="fireteam-type-id"
        label="Fire Team Type"
        name="fireteam-type"
        onChange={(e) => setFireTeam({ ...fireTeam, type: e.target.value })}
        value={fireTeam?.type || ''}
        helperText="Example: Intercessor, Plague Marine"
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="descr"
        label="Description"
        name="firetream-descr"
        onChange={(e) => setFireTeam({ ...fireTeam, descr: e.target.value })}
        value={fireTeam?.descr || ''}
        multiline
        maxRows={4}
      />
      <FactionSelect
        onChange={(factionKeyword) => setFireTeam({ ...fireTeam, factionKeyword })}
        selected={fireTeam?.factionKeyword}
      />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-label">Unit Amount</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => setFireTeam({ ...fireTeam, amount: Number(e.target.value) })}
          value={fireTeam?.amount ? fireTeam?.amount.toString() : ''}
          fullWidth
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
        </Select>
        <FormHelperText>Including Sergeant or similar</FormHelperText>
      </FormControl>
      <Typography variant="h6">Units</Typography>
      {fireTeam?.units &&
        fireTeam.units.map((fireTeamUnit, i) => (
          <FireTeamUnit
            key={`${fireTeamUnit.id}-${i}`}
            index={i}
            units={units}
            fireTeamUnits={fireTeam.units}
            setFireTeamUnits={(units) => setFireTeam({ ...fireTeam, units })}
            fireTeamUnitId={fireTeamUnit.id}
          />
        ))}

      <FireTeamUnit
        index={fireTeamUnitsCount}
        units={units}
        fireTeamUnits={fireTeam?.units}
        setFireTeamUnits={(units) => setFireTeam({ ...fireTeam, units })}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // disabled={fireTeam && fireTeam.type && !fireTeam.type.length}
      >
        {mode === 'ADD' ? 'Add Fire Team' : 'Edit Fire Team'}
      </Button>
    </form>
  );
};

export default AddFireTeamForm;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '../List';
import AddWeaponForm from './AddWeaponForm';
import { isInComplete, sortByType } from './AddWeapon.helper';
import type { WeaponType, WeaponDataType } from '../../../types';
import FormLayout from '../FormLayout';
import { useStateContext } from '../../StateProvider';
import { onDelete, onEdit, onAdd } from '../Admin.helper';

const AddWeapon = (): JSX.Element => {
  const [state, send] = useStateContext();
  const { weapons, filterBy }: { weapons: WeaponType[]; filterBy: string } = state.context;
  const [selectedWeapon, setSelectedWeapon] = React.useState<WeaponType>();
  const sortedWeapons = weapons
    ? weapons.sort(sortByType).filter((w) => (filterBy ? w.factionKeyword === filterBy : true))
    : [];

  const onAddClick = (weapon: Partial<WeaponDataType>): void => {
    onAdd(send, weapon, 'ADD_WEAPON', `${weapon.type} added!`);
  };

  const onEditClick = (weapon: WeaponType) => {
    onEdit(send, weapon, 'EDIT_WEAPON', `${weapon.type} updated!`);
  };

  const onDeleteClick = (id: string) => {
    onDelete(send, id, 'DELETE_WEAPON', 'Weapon deleted!');
  };

  const onSelectClick = (id: string) => {
    const select = weapons.find((a: WeaponType) => a.id === id);

    if (select) {
      setSelectedWeapon(select);
    }
  };

  if (state.matches({ authorized: 'editingWeapon' }) || state.matches({ authorized: 'addingWeapon' }))
    return <CircularProgress />;

  return (
    <FormLayout
      title="Add Weapon"
      section1={
        weapons !== undefined ? (
          <List
            title={`Weapons (${sortedWeapons.length} / ${weapons.length})`}
            items={sortedWeapons as unknown as Record<string, unknown>[]}
            onItemClick={onSelectClick}
            inComplete={isInComplete}
            onItemDelete={onDeleteClick}
          />
        ) : (
          <CircularProgress />
        )
      }
      section2={
        <AddWeaponForm
          onAdd={onAddClick}
          onEdit={onEditClick}
          selected={selectedWeapon}
          selectedFactionKeyword={filterBy}
        />
      }
    />
  );
};

export default AddWeapon;

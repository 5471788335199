import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AddFaction from './AddFaction';
import AddFireTeam from './AddFireTeam';
import AddUnit from './AddUnit';
import { useStateContext } from '../StateProvider';
import AddAbilities from './AddAbilities';
import AddWeapon from './AddWeapon';
import AddLoadout from './AddLoadout';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    ' &  .MuiTab-root': {
      fontSize: '0.7rem',
      minWidth: '90px',
    },
  },
}));

const Admin = (): JSX.Element => {
  const classes = useStyles();
  const [
    {
      context: { adminTabIndex },
    },
    send,
  ] = useStateContext();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    send('SET_TAB_INDEX', { tabIndex: newValue });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={adminTabIndex}
          onChange={handleChange}
          aria-label="Admin tab navigation"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Factions" {...a11yProps(0)} />
          <Tab label="Fire Teams" {...a11yProps(1)} />
          <Tab label="Units" {...a11yProps(2)} />
          <Tab label="Loadout" {...a11yProps(3)} />
          <Tab label="Weapons" {...a11yProps(4)} />
          <Tab label="Abilities" {...a11yProps(5)} />
          <Tab label="Unique Actions" {...a11yProps(6)} disabled />
          <Tab label="Archetype" {...a11yProps(7)} disabled />
        </Tabs>
      </AppBar>
      <TabPanel value={adminTabIndex} index={0}>
        <AddFaction />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={1}>
        <AddFireTeam />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={2}>
        <AddUnit />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={3}>
        <AddLoadout />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={4}>
        <AddWeapon />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={5}>
        <AddAbilities />
      </TabPanel>
      <TabPanel value={adminTabIndex} index={6}>
        Add Unique Actions
      </TabPanel>
      <TabPanel value={adminTabIndex} index={7}>
        Add Archetype
      </TabPanel>
    </div>
  );
};

export default Admin;

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withAuth } from '../../Auth';
import List from '../List';
import AddAblitiesForm from './AddAblitiesForm';
import type { AbilityType, AbilityDataType } from '../../../types';
import { useStateContext } from '../../StateProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const AddAbilities = () => {
  const classes = useStyles();
  const [
    {
      context: { abilities },
    },
    send,
  ] = useStateContext();
  const [selectedAbility, setSelectedAbility] = React.useState<AbilityType>();

  const onAdd = (ability: AbilityDataType): void => {
    send('ADD_ABILITY', { ability });
  };

  const onEdit = (ability: AbilityType) => {
    send('EDIT_ABILITY', { ability });
  };

  const onDelete = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      send('DELETE_ABILITY', { id });
    }
  };

  const onSelectAbility = (id: string) => {
    const select: AbilityType = abilities.find((a: AbilityType) => a.id === id);

    if (select) {
      setSelectedAbility(select);
    }
  };

  const isInComplete = (item: Partial<AbilityType>): string => {
    const errorMessage = [];
    const hasDescr = !!item.descr;

    if (!hasDescr) {
      errorMessage.push('No Description');
    }

    return errorMessage.join(', ');
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" style={{ marginTop: '1rem' }}>
            Add Ability
          </Typography>
        </Grid>
        <Grid item sm={5} xs={12}>
          <Paper className={classes.paper}>
            {abilities ? (
              <List
                title={`Abilities (${abilities.length})`}
                items={abilities.sort((a: AbilityType, b: AbilityType) => {
                  if (a.type > b.type) {
                    return 1;
                  }
                  if (a.type < b.type) {
                    return -1;
                  }
                  return 0;
                })}
                onItemClick={onSelectAbility}
                inComplete={isInComplete}
                onItemDelete={onDelete}
              />
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </Grid>
        <Grid item sm={7} xs={12}>
          <Paper className={classes.paper}>
            <AddAblitiesForm onAdd={onAdd} onEdit={onEdit} selected={selectedAbility} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withAuth(AddAbilities);

export const base080 = '#EEEEEE';
export const base090 = '#d6d5d3';
export const base = '#AAAAAA';
export const base110 = '#555555';
export const base120 = '#252525';
export const base130 = '#1a1406';
export const black = '#000000';
export const white = '#ffffff';
export const error090 = '#FFE5E6';
export const error = '#E51B22';
export const error110 = '#991216';
export const highlight090 = '#85E0FF';
export const highlight = '#00BEFF';
export const highlight110 = '#007299';
export const success090 = '#E5FFF2';
export const success = '#24874E';
export const success110 = '#24874E';
export const interactive090 = '#FFEF6E';
export const interactive = '#FFE71F';
export const interactive110 = '#F0CD0E';
export const brand090 = '#f3dfcd';
export const accent = '#C6A224';
export const red = '#c65124';
export const darkRed = '#581845';
export const green = '#99C624';
export const green2 = '#48C624';
export const green3 = '#24C651';
export const green4 = '#24C6A2';

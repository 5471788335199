import React from 'react';
import type { IconType } from './icon.types';

const Skull = ({ color = '#000', width = '100%', className = '', height = '100%' }: IconType): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 486.7 660.7"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M476.1,144.8C434,22,309.7,2.8,255.9,0.3l0,0c0,0-4.6-0.3-12.4-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
c-7.9,0-12.4,0.3-12.4,0.3l0,0C177,2.8,52.6,22,10.6,144.8c-35.9,104.8,32.1,223.2,18.3,239.1c-55.3,64,19.9,165.5,30.1,173.8
c18.5,15.1,77.3-15.5,68,0.3c-7,12-13.5,102.7-13.5,102.7h18.2l8.7-31.6l15.6-0.2l3,31.8h31.8l3-30.3l10.6-1.5l1.5,31.8h25.8
l1.2-32.6h20.9l1.2,32.6h25.8l1.5-31.8l10.6,1.5l3,30.3h31.8l3-31.8l15.6,0.2l8.7,31.6h18.2c0,0-6.5-90.7-13.5-102.7
c-9.3-15.8,49.5,14.7,68-0.3c10.3-8.4,85.4-109.8,30.1-173.8C444,367.9,511.9,249.5,476.1,144.8z M209.1,450.1
c-7.2,8.1-17.6,17.7-31.7,25.6c-33.9,19.1-67.5,16.4-81.1,14.5c-0.9-0.1-1.8-0.4-2.5-0.9c-29.7-18.8-41-48.1-32.2-66.4
c1.3-2.8,6.3-12,21.9-17.7c0.9-0.3,1.8-0.4,2.7-0.4c18.8,1.7,42.1,5.4,67.9,13.5c20.9,6.5,38.7,14.4,53.3,22
C211,442.2,211.8,447,209.1,450.1z M280.1,561c-0.1,2.1-2.7,2.9-4,1.3c-3.2-4.1-7.6-8.8-13.5-13.1c-6.3-4.6-12.3-7.5-17-9.2
c-4.6,1.7-10.7,4.6-17,9.2c-5.9,4.3-10.3,9-13.5,13.1c-1.3,1.6-3.9,0.8-4-1.3c-1-15.5-0.3-47.2,23-70.2c3-3,6.9-5.6,11.5-8.1
c4.5,2.5,8.5,5.1,11.5,8.1C280.4,513.8,281.1,545.6,280.1,561z M393.7,489.3c-0.8,0.5-1.6,0.8-2.5,0.9c-13.6,1.9-47.1,4.6-81.1-14.5
c-14.1-7.9-24.5-17.5-31.7-25.6c-2.7-3.1-1.9-7.9,1.8-9.8c14.6-7.6,32.4-15.5,53.3-22c25.9-8.1,49.2-11.8,67.9-13.5
c0.9-0.1,1.8,0,2.7,0.4c15.5,5.7,20.5,15,21.9,17.7C434.7,441.2,423.4,470.6,393.7,489.3z"
    />
  </svg>
);

export default Skull;

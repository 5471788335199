import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    listItemText: {
      fontSize: '0.8em',
    },
    listItemErrorText: {
      color: theme.palette.warning.main,
    },
  }),
);

const AdminList = ({
  title,
  items,
  onItemClick,
  inComplete,
  onItemDelete,
}: {
  title?: string;
  items: Array<Record<string, unknown>>;
  onItemClick?: (id: string) => void;
  inComplete?: (item: Record<string, unknown>) => string;
  onItemDelete?: (id: string) => void;
}): JSX.Element => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, id: string) => {
    setSelectedIndex(index);
    onItemClick && onItemClick(id);
  };

  return (
    <div className={classes.root}>
      {title && <Typography variant="h6">{title}</Typography>}
      <List dense component="nav" aria-label="main mailbox folders">
        {items.map((item, i) => {
          return (
            <ListItem
              key={item.id as string}
              button
              selected={selectedIndex === i}
              onClick={(event) => handleListItemClick(event, i, item.id as string)}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText
                primary={item.type as string}
                classes={{ primary: classes.listItemText, secondary: classes.listItemErrorText }}
                secondary={inComplete && inComplete(item)}
              />
              {onItemDelete && (
                <ListItemSecondaryAction onClick={() => onItemDelete(item.id as string)}>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default AdminList;

import React, { ReactElement } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import type { FactionType } from '../../../types';

const SelectFaction = ({
  factions,
  faction,
  onChangeFaction,
}: {
  factions: Array<FactionType>;
  faction?: FactionType;
  onChangeFaction: (e: React.ChangeEvent<{ value: unknown }>) => void;
}): ReactElement => {
  return (
    <div>
      <InputLabel id="factionId">Faction</InputLabel>
      <Select
        labelId="factionId"
        id="factionId"
        value={faction ? faction.id : ''}
        fullWidth
        onChange={onChangeFaction}
        required
      >
        {factions.map((f) => (
          <MenuItem key={f.id} value={f.id}>
            {f.type}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectFaction;

import React from 'react';
import MaterialUISnackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useStateContext } from './StateProvider';
import { SnackbarType } from '../types';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = (): JSX.Element => {
  const [{ context }, send] = useStateContext();
  const {
    snackbar: { type, text, isVisible },
  }: { snackbar: SnackbarType } = context;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    send('CLOSE_SNACKBAR');
  };

  return (
    <MaterialUISnackbar open={isVisible} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {text}
      </Alert>
    </MaterialUISnackbar>
  );
};

export default Snackbar;

import { formatDistance } from 'date-fns';
import type { SnackbarType, WeaponType, WeaponDataType } from '../../types';
export const formatDistanceInWords = (now: Date, prefixText = 'Updated'): string => {
  const distance = formatDistance(now, new Date(), { addSuffix: true });
  return `${prefixText} ${distance}`;
};

export const onDelete = (
  send: (value: string, data: unknown) => void,
  id: string,
  actionName: string,
  text: string,
): void => {
  const isConfirmed = confirm('Are you sure?');

  if (isConfirmed == true) {
    const snackbar: SnackbarType = {
      type: 'error',
      text,
    };
    send(actionName, { id, snackbar });
  }
};

export const onEdit = (
  send: (value: string, data: unknown) => void,
  weapon: WeaponType,
  actionName: string,
  text: string,
): void => {
  const snackbar: SnackbarType = {
    type: 'warning',
    text,
  };
  send(actionName, { weapon, snackbar });
};

export const onAdd = (
  send: (value: string, data: unknown) => void,
  weapon: Partial<WeaponDataType>,
  actionName: string,
  text: string,
): void => {
  const snackbar: SnackbarType = {
    type: 'success',
    text,
  };
  send(actionName, { weapon, snackbar });
};

import React from 'react';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { factionArray } from '../../constants/factions';
import { useStateContext } from '../StateProvider';

const Filter = (): JSX.Element => {
  const [state, send] = useStateContext();
  const { filterBy }: { filterBy: string } = state.context;

  const onChange = (id: string) => {
    send('ADMIN_FILTER_BY', { filterBy: id });
  };
  return (
    <Container maxWidth="xs">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Filter by Faction</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => onChange(e.target.value as string)}
          value={filterBy}
          fullWidth
        >
          <MenuItem value="">No Filter</MenuItem>
          {factionArray.map((faction: string, i) => {
            return (
              <MenuItem key={i} value={faction}>
                {faction}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Container>
  );
};

export default Filter;

import * as React from 'react';
import Firebase from './firebase';

const FirebaseContext = React.createContext(new Firebase());

export default FirebaseContext;

export const useFirebase = () => React.useContext(FirebaseContext);

export const AuthUserContext = React.createContext({
  user: null,
});

import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CStudio } from '../assets/icons';
import Icon from './Icon';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://craven-studio.com/">
        Craven Studio
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    marginTop: 'auto',
  },
}));

const Footer = (): JSX.Element => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom></Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        <CStudio width="90px" color="#f2581a" />
      </Typography>
      <Copyright />
    </footer>
  );
};

export default Footer;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStateContext } from '../../StateProvider';
import type { FactionFireTeamType, FactionType, SnackbarType, UserTeamType } from '../../../types';
import SelectFaction from './SelectFaction';
import SelectFireTeams from './SelectFireTeams';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddTeamForm = ({ selected }: { selected?: UserTeamType }): JSX.Element => {
  const classes = useStyles();
  const [current, send] = useStateContext();
  const {
    user: { uid },
  } = current.context;
  const { factions }: { factions: Array<FactionType> } = current.context;

  const [name, setName] = React.useState('');
  const [faction, setFaction] = React.useState<FactionType>();
  const [fireTeams, setFireTeams] = React.useState<Array<FactionFireTeamType>>([]);
  const [mode, setMode] = React.useState('ADD');

  const onChangeFaction = (e: React.ChangeEvent<{ value: unknown }>) => {
    const factionId = e.target.value;
    const selectedFaction = factions.find((f) => f.id === factionId);

    if (selectedFaction) {
      setFaction(selectedFaction);
      setFireTeams([]);
    }
  };

  const onChangeFireTeams = (e: React.ChangeEvent<{ value: unknown; name?: string }>, ind?: number): void => {
    const fireTeamId = e.target.value as string;
    if (!fireTeamId) {
      const updatedFireTeams = fireTeams.filter((_, i) => i !== ind);
      setFireTeams(updatedFireTeams);
      return;
    }

    const index = e.target.name && e.target.name.split('-')[0];
    const selectedFireTeam = faction && faction.fireTeams.find((fireTeam) => fireTeam.id === fireTeamId);

    if (selectedFireTeam) {
      if (Number(index) === fireTeams.length) {
        // add new
        setFireTeams([...fireTeams, selectedFireTeam]);
      } else {
        const updatedFireTeamUnits = fireTeams.map((fireTeam, i) => {
          if (i === Number(index)) {
            return selectedFireTeam;
          }

          return fireTeam;
        });

        setFireTeams(updatedFireTeamUnits);
      }
    }
  };

  const addNewTeam = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (mode === 'ADD') {
      const snackbar: SnackbarType = {
        type: 'success',
        text: `${name} added!`,
      };
      send('ADD_USER_TEAM', { userTeam: { uid, name, faction, fireTeams }, snackbar });
    } else {
      if (selected) {
        const snackbar: SnackbarType = {
          type: 'warning',
          text: `${name} updated!`,
        };
        send('EDIT_USER_TEAM', { userTeam: { id: selected.id, name, faction, fireTeams }, snackbar });
      }
    }
  };

  React.useEffect(() => {
    if (selected) {
      const { name, faction, fireTeams } = selected;

      setName(name);
      setFaction(faction);
      setFireTeams(fireTeams);
      setMode('EDIT');
    }
  }, [selected]);

  if (current.matches({ authorized: 'loadingFactions' })) return <CircularProgress />;

  return (
    <div className={classes.paper}>
      <form className={classes.form} noValidate>
        <TextField
          id="teamName"
          label="Team Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
          fullWidth
        />
        <FormHelperText id="my-helper-text">A man needs a name</FormHelperText>
        <SelectFaction factions={factions} faction={faction} onChangeFaction={onChangeFaction} />
        {faction && (
          <SelectFireTeams
            maxFireTeams={faction.maxFireTeams}
            factionFireTeams={faction.fireTeams}
            selectedFireTeams={fireTeams}
            onChangeFireTeams={onChangeFireTeams}
            setFireTeams={setFireTeams}
            mode={mode}
          />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={addNewTeam}
          disabled={!name || !faction}
        >
          {mode === 'ADD' ? 'Add' : 'Edit'} Team
        </Button>
      </form>
    </div>
  );
};

export default AddTeamForm;

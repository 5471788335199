import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FactionFireTeam from './FactionFireTeam';
import type { FactionFireTeamType, FireTeamType, FactionType, FactionDataType } from '../../../types';
import { formatDistanceInWords } from '../Admin.helper';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddFactionForm = ({
  fireTeams,
  onAddFaction,
  onEditFaction,
  selectedFaction,
}: {
  fireTeams: Array<FireTeamType>;
  onAddFaction: (value: Partial<FactionDataType>) => void;
  onEditFaction: (value: Partial<FactionType>) => void;
  selectedFaction: FactionType | undefined;
}): JSX.Element => {
  const classes = useStyles();
  // const [type, setType] = React.useState('');
  // const [descr, setDescr] = React.useState('');
  // const [maxFireTeams, setMaxFireTeams] = React.useState(1);
  // const [factionFireTeams, setFactionFireTeams] = React.useState<Array<FactionFireTeamType>>([]);
  // const [updatedAt, setUpdatedAt] = React.useState<number>();
  const [faction, setFaction] = React.useState<Partial<FactionDataType>>();
  const [mode, setMode] = React.useState('ADD');
  const factionfireTeamsCount = faction && faction.fireTeams ? faction.fireTeams.length : 0;

  const resetForm = () => {
    // setType('');
    // setDescr('');
    // setFactionFireTeams([]);
    // setUpdatedAt(undefined);
    setFaction(undefined);
    setMode('ADD');
  };

  const onResetClick = () => {
    resetForm();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (mode === 'ADD') {
      if (faction) {
        onAddFaction(faction);
      }
    } else {
      if (faction) {
        onEditFaction(faction);
      }
    }

    resetForm();
  };

  React.useEffect(() => {
    if (selectedFaction) {
      setFaction(selectedFaction);
      setMode('EDIT');
    }
  }, [selectedFaction]);
  console.log({ faction });
  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <Button onClick={onResetClick} fullWidth variant="contained" color="secondary">
        New
      </Button>
      <FormHelperText style={{ margin: '0.5rem 0' }}>{`${
        selectedFaction && selectedFaction.updatedAt ? formatDistanceInWords(new Date(selectedFaction.updatedAt)) : ''
      }`}</FormHelperText>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="faction-name"
        onChange={(e) => setFaction({ ...faction, type: e.target.value })}
        value={faction?.type || ''}
        helperText="For example: Space Marine, T'au Empire, Death Guard"
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="descr"
        label="Description"
        name="faction-descr"
        autoComplete="descr"
        onChange={(e) => setFaction({ ...faction, descr: e.target.value })}
        value={faction?.descr || ''}
        multiline
        maxRows={4}
      />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-label">Maximum amount of Fire Teams</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => setFaction({ ...faction, maxFireTeams: Number(e.target.value) })}
          value={faction?.maxFireTeams ? faction?.maxFireTeams.toString() : ''}
          fullWidth
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h6">Add Fire Teams</Typography>
      {faction?.fireTeams && (
        <div>
          {faction.fireTeams.map((factionFireTeam, i) => (
            <FactionFireTeam
              key={`${factionFireTeam.id}-${i}`}
              index={i}
              fireTeams={fireTeams}
              factionFireTeams={faction.fireTeams}
              setFactionFireTeams={(fireTeams) => setFaction({ ...faction, fireTeams })}
              factionFireTeamId={factionFireTeam.id}
            />
          ))}
        </div>
      )}
      <FactionFireTeam
        index={factionfireTeamsCount}
        fireTeams={fireTeams}
        factionFireTeams={faction?.fireTeams}
        factionFireTeamId={''}
        setFactionFireTeams={(fireTeams) => setFaction({ ...faction, fireTeams })}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // disabled={!type.length || !factionFireTeams.length}
      >
        {mode === 'ADD' ? 'Add Faction' : 'Edit Faction'}
      </Button>
    </form>
  );
};

export default AddFactionForm;

import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import SelectFireTeamUnits from './SelectFireTeamUnits';
import type { FactionFireTeamType } from '../../../types';
import { Divider } from '@material-ui/core';

const SelectFireTeams = ({
  factionFireTeams,
  maxFireTeams,
  selectedFireTeams,
  onChangeFireTeams,
  setFireTeams,
  mode,
}: {
  factionFireTeams: Array<FactionFireTeamType>;
  maxFireTeams: number;
  selectedFireTeams: Array<FactionFireTeamType>;
  onChangeFireTeams: (e: React.ChangeEvent<{ value: unknown; name?: string }>, index?: number) => void;
  setFireTeams: Dispatch<SetStateAction<Array<FactionFireTeamType>>>;
  mode: string;
}): ReactElement => {
  const fireTeamsMarkup = Array.from({ length: maxFireTeams }, (_, i) => i).map((index) => {
    const selectedFireTeam = selectedFireTeams && selectedFireTeams[index];
    return (
      <div key={index} style={{ padding: '0.5rem 0' }}>
        <InputLabel id={`fire-team-label-${index}`}>
          Fire Team #{index + 1}
          {selectedFireTeam && selectedFireTeam.limit && (
            <span style={{ color: 'red' }}>{` (Max one per Kill Team)`}</span>
          )}
        </InputLabel>

        <Select
          labelId={`fire-team-label-${index}`}
          id={`${index}-select-id`}
          name={`${index}-select-id`}
          onChange={(e) => onChangeFireTeams(e, index)}
          value={selectedFireTeam ? selectedFireTeam.id : ''}
          fullWidth
          required
        >
          <MenuItem value="">None</MenuItem>
          {factionFireTeams.map((factionFireTeam) => (
            <MenuItem key={factionFireTeam.id} value={factionFireTeam.id}>
              {factionFireTeam.type} Fire Team
            </MenuItem>
          ))}
        </Select>
        {selectedFireTeam && (
          <SelectFireTeamUnits
            factionFireTeamUnits={factionFireTeams.filter((fft) => fft.id === selectedFireTeam.id)[0].units}
            selectedFireTeams={selectedFireTeams}
            selectedFireTeam={selectedFireTeam}
            setFireTeams={setFireTeams}
            fireTeamIndex={index}
            mode={mode}
          />
        )}
        <Divider variant="fullWidth" style={{ margin: '2rem 0 1rem' }} />
      </div>
    );
  });

  return (
    <div style={{ padding: '1rem 0' }}>
      <Typography variant="h6">Choose {maxFireTeams} Fire Teams</Typography>
      {fireTeamsMarkup}
    </div>
  );
};

export default SelectFireTeams;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddUnitForm from './AddUnitForm';
import { isUnitInComplete, sortByType } from './AddUnit.helper';
import List from '../List';
import type { UnitDataType, UnitType, SnackbarType } from '../../../types';
import { useStateContext } from '../../StateProvider';
import FormLayout from '../FormLayout';

const DEATHGUARD = ['DEATH GUARD', 'CHAOS', 'BUBONIC ASTARTES', 'PLAGUE MARINE'];
const ADEPTUSASTARTES = ['SPACE MARINE', 'IMPERIUM', 'ADEPTUS ASTARTES'];
const GREENSKIN = ['GREENSKIN', 'ORK'];
const NECRONS = ['NECRON'];
const KOMMANDO = ['KOMMANDO', 'ORK'];

const factionKeywordsPresets: { [key: string]: string[] } = {
  DEATHGUARD,
  ADEPTUSASTARTES,
  GREENSKIN,
  NECRONS,
  KOMMANDO,
};

const AddUnit = (): JSX.Element => {
  const [state, send] = useStateContext();
  const { units, filterBy }: { units: UnitType[]; filterBy: string } = state.context;
  const [selectedUnit, setSelectedUnit] = React.useState<UnitType>();
  const sortedUnits = units
    ? units
        .sort(sortByType)
        .filter((u) => (filterBy ? u.keywords && u.keywords.includes(filterBy.toUpperCase()) : true))
    : [];

  const onAddUnitClick = (unit: Partial<UnitDataType>) => {
    const snackbar: SnackbarType = {
      type: 'success',
      text: `${unit.type} added!`,
    };

    send('ADD_UNIT', { unit, snackbar });
  };

  const onEditUnitClick = (unit: Partial<UnitType>) => {
    const snackbar: SnackbarType = {
      type: 'warning',
      text: `${unit.type} updated!`,
    };

    send('EDIT_UNIT', { unit, snackbar });
  };

  const onDeleteUnitClick = (id: string) => {
    const isConfirmed = confirm('Are you sure?');

    if (isConfirmed == true) {
      const snackbar: SnackbarType = {
        type: 'error',
        text: `Unit deleted!`,
      };
      send('DELETE_UNIT', { id, snackbar });
    }
  };

  const onSelectUnitClick = (id: string) => {
    const selectUnit = units.find((u: UnitType) => u.id === id);

    if (selectUnit) {
      setSelectedUnit(selectUnit);
    }
  };

  if (state.matches({ authorized: 'editingUnit' }) || state.matches({ authorized: 'addingUnit' }))
    return <CircularProgress />;

  return (
    <FormLayout
      title="Add Unit"
      section1={
        units !== undefined ? (
          <List
            title={`Units (${sortedUnits.length} / ${units.length})`}
            items={sortedUnits as unknown as Record<string, unknown>[]}
            onItemClick={onSelectUnitClick}
            inComplete={isUnitInComplete}
            onItemDelete={onDeleteUnitClick}
          />
        ) : (
          <CircularProgress />
        )
      }
      section2={
        <AddUnitForm
          onAddUnit={onAddUnitClick}
          onEditUnit={onEditUnitClick}
          selectedUnit={selectedUnit}
          unitKeywords={filterBy ? factionKeywordsPresets[filterBy.replace(/\s/g, '').toUpperCase()] : []}
        />
      }
    />
  );
};

export default AddUnit;

type FirebaseErrorType = {
  code: string;
  customData: unknown;
  name: string;
};

export const firebaseErrorMessage = (error: FirebaseErrorType): string => {
  const { code } = error;

  switch (code) {
    case 'auth/invalid-email':
      return 'Invalid password or email provided.';
    default:
      return 'Something went wrong.';
  }
};

import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { factionArray } from '../../../constants/factions';

const FactionSelect = ({ onChange, selected = '' }: { onChange: (e: any) => void; selected?: string }): JSX.Element => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Add Faction Keyword</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={(e) => onChange(e.target.value)}
        value={selected}
        fullWidth
      >
        <MenuItem value="">None</MenuItem>
        {factionArray.map((faction: string, i) => {
          return (
            <MenuItem key={i} value={faction}>
              {faction}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FactionSelect;

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  Firestore,
  addDoc,
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  Auth,
  UserCredential,
} from 'firebase/auth';
import { firebaseErrorMessage } from './firebase.helper';

import config from './config';
import type {
  FactionType,
  FactionDataType,
  UserTeamType,
  UnitType,
  UnitDataType,
  FireTeamType,
  FireTeamDataType,
  AbilityType,
  AbilityDataType,
  WeaponType,
  WeaponDataType,
  LoadoutType,
  LoadoutDataType,
  UserType,
} from '../types';

class Firebase {
  db: Firestore;
  auth: Auth;

  constructor() {
    initializeApp(config);

    this.db = getFirestore();
    this.auth = getAuth();

    if (process.env.NODE_ENV === 'development') {
      setPersistence(this.auth, browserLocalPersistence);
    }

    // const db = firestore.firestore();
    // db.settings({ ignoreUndefinedProperties: true });
  }

  signInWithEmailAndPassword = ({ email, password }: { email: string; password: string }): Promise<UserCredential> => {
    return signInWithEmailAndPassword(this.auth, email, password).catch((error) => {
      return Promise.reject(firebaseErrorMessage(error));
    });
  };

  signOut = (): void => {
    signOut(this.auth);
  };

  getUserDocument = async (uid: string): Promise<UserType | null> => {
    if (!uid) return null;

    try {
      const docRef = await doc(this.db, 'users', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data() as UserType;
      } else {
        throw new Error('No such document');
      }
    } catch (error) {
      console.error('Error fetching user', error);
    }

    return null;
  };

  mergeUser = async (authUser: UserType, next: any): Promise<void> => {
    if (!authUser) {
      next();
      return;
    }

    const user = await this.getUserDocument(authUser.uid);

    if (user) {
      const mergedUser = {
        ...user,
        uid: authUser.uid,
        email: authUser.email,
      };

      next(mergedUser);
    }

    next({
      uid: authUser.uid,
      email: authUser.email,
    });
  };

  onAuthListener = (): Promise<UserType> =>
    new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(this.auth, (_auth: any) => {
        this.mergeUser(_auth, (authUser: UserType) => {
          unsubscribe();
          resolve(authUser);
        });
      });
    });

  getFactions = async (): Promise<Array<FactionType>> => {
    const promises: Array<FactionType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'factions'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as FactionType), id: doc.id });
    });

    return Promise.all(promises);
  };

  addFaction = async (faction: FactionDataType): Promise<FactionType> => {
    const createdAt = serverTimestamp();
    const updatedAt = Date.now();
    const docRef = await addDoc(collection(this.db, 'factions'), {
      ...faction,
      createdAt,
      updatedAt,
    });

    return Promise.resolve({ ...faction, id: docRef.id, createdAt, updatedAt });
  };

  updateFaction = async (updatedFaction: FactionType): Promise<FactionType> => {
    const docRef = doc(this.db, 'factions', updatedFaction.id);
    const updatedAt = Date.now();
    const faction = { ...updatedFaction, updatedAt };

    await updateDoc(docRef, { ...faction });

    return Promise.resolve(faction);
  };

  deleteFaction = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'factions', id));

    return id;
  };

  addUserTeam = async (uid: string, userTeam: UserTeamType): Promise<UserTeamType> => {
    const createdAt = serverTimestamp() as Timestamp;
    const docRef = await addDoc(collection(this.db, 'userTeams'), {
      ...userTeam,
      createdAt,
      owner: uid,
    });

    return await Promise.resolve({ ...userTeam, id: docRef.id, createdAt });
  };

  getUserTeams = async (uid: string): Promise<Array<UserTeamType>> => {
    const promises: Array<UserTeamType> = [];
    const q = query(collection(this.db, 'userTeams'), where('owner', '==', uid));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as UserTeamType), id: doc.id });
    });

    return Promise.all(promises);
  };

  updateUserTeam = async (team: UserTeamType): Promise<UserTeamType> => {
    const docRef = doc(this.db, 'userTeams', team.id);

    await updateDoc(docRef, { ...team });

    return Promise.resolve(team);
  };

  deleteUserTeam = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'userTeams', id));

    return id;
  };

  addFireTeam = async (fireTeam: FireTeamDataType): Promise<FireTeamType> => {
    const createdAt = serverTimestamp() as Timestamp;
    const updatedAt = Date.now();
    const docRef = await addDoc(collection(this.db, 'fireTeams'), {
      ...fireTeam,
      createdAt,
      updatedAt,
    });

    return Promise.resolve({ ...fireTeam, id: docRef.id, createdAt, updatedAt });
  };

  updateFireTeam = async (updatedFireTeam: FireTeamType): Promise<FireTeamType> => {
    const docRef = doc(this.db, 'fireTeams', updatedFireTeam.id);
    const updatedAt = Date.now();
    const fireTeam = { ...updatedFireTeam, updatedAt };

    await updateDoc(docRef, { ...fireTeam });

    return Promise.resolve(fireTeam);
  };

  getFireTeams = async (): Promise<Array<FireTeamType>> => {
    const promises: Array<FireTeamType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'fireTeams'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as FireTeamType), id: doc.id });
    });

    return Promise.all(promises);
  };

  deleteFireTeam = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'fireTeams', id));

    return id;
  };

  addUnit = async (unit: UnitDataType): Promise<UnitType> => {
    const createdAt = serverTimestamp();
    const updatedAt = Date.now();
    const docRef = await addDoc(collection(this.db, 'units'), {
      ...unit,
      createdAt,
      updatedAt,
    });

    return Promise.resolve({ ...unit, id: docRef.id, createdAt, updatedAt });
  };

  deleteUnit = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'units', id));

    return id;
  };

  updateUnit = async (updatedUnit: UnitType): Promise<UnitType> => {
    const docRef = doc(this.db, 'units', updatedUnit.id);
    const updatedAt = Date.now();
    const unit = { ...updatedUnit, updatedAt };

    await updateDoc(docRef, { ...unit });

    return Promise.resolve(unit);
  };

  getUnits = async (): Promise<Array<UnitType>> => {
    const promises: Array<UnitType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'units'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as UnitType), id: doc.id });
    });

    return Promise.all(promises);
  };

  getAbilities = async (): Promise<Array<AbilityType>> => {
    const promises: Array<AbilityType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'abilities'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as AbilityType), id: doc.id });
    });

    return Promise.all(promises);
  };

  addAbility = async (ability: AbilityDataType): Promise<AbilityType> => {
    const createdAt = serverTimestamp() as Timestamp;
    const docRef = await addDoc(collection(this.db, 'abilities'), {
      ...ability,
      createdAt,
    });

    return Promise.resolve({ ...ability, id: docRef.id, createdAt });
  };

  updateAbility = async (ability: AbilityType): Promise<AbilityType> => {
    const docRef = doc(this.db, 'abilities', ability.id);

    await updateDoc(docRef, { ...ability });

    return Promise.resolve(ability);
  };

  deleteAbility = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'abilities', id));

    return id;
  };

  getWeapons = async (): Promise<Array<WeaponType>> => {
    const promises: Array<WeaponType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'weapons'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as WeaponType), id: doc.id });
    });

    return Promise.all(promises);
  };

  addWeapon = async (weapon: WeaponDataType): Promise<WeaponType> => {
    const createdAt = serverTimestamp();
    const updatedAt = Date.now();
    const docRef = await addDoc(collection(this.db, 'weapons'), {
      ...weapon,
      createdAt,
      updatedAt,
    });

    return Promise.resolve({ ...weapon, id: docRef.id, createdAt, updatedAt });
  };

  updateWeapon = async (updatedWeapon: WeaponType): Promise<WeaponType> => {
    const docRef = doc(this.db, 'weapons', updatedWeapon.id);
    const updatedAt = Date.now();
    const weapon: WeaponType = { ...updatedWeapon, updatedAt };

    await updateDoc(docRef, { ...weapon });

    return Promise.resolve(weapon);
  };

  deleteWeapon = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'weapons', id));

    return id;
  };

  getLoadouts = async (): Promise<Array<LoadoutType>> => {
    const promises: Array<LoadoutType> = [];
    const querySnapshot = await getDocs(collection(this.db, 'loadouts'));
    querySnapshot.forEach((doc) => {
      promises.push({ ...(doc.data() as LoadoutType), id: doc.id });
    });

    return Promise.all(promises);
  };

  addLoadout = async (loadout: LoadoutDataType): Promise<LoadoutType> => {
    const createdAt = serverTimestamp();
    const updatedAt = Date.now();
    const docRef = await addDoc(collection(this.db, 'loadouts'), {
      ...loadout,
      createdAt,
    });

    return Promise.resolve({ ...loadout, id: docRef.id, createdAt, updatedAt });
  };

  updateLoadout = async (updatedLoadout: LoadoutType): Promise<LoadoutType> => {
    const docRef = doc(this.db, 'loadouts', updatedLoadout.id);
    const loadout: LoadoutType = { ...updatedLoadout, updatedAt: Date.now() };

    await updateDoc(docRef, { ...loadout });

    return Promise.resolve(loadout);
  };

  deleteLoadout = async (id: string): Promise<string> => {
    await deleteDoc(doc(this.db, 'loadouts', id));

    return id;
  };
}

export default Firebase;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import type { AbilityType } from '../../../types';
import { useStateContext } from '../../StateProvider';

const UnitAbilities = ({
  abilities = [],
  setAbilities,
}: {
  abilities?: AbilityType[];
  setAbilities: (values: AbilityType[]) => void;
}): JSX.Element => {
  const [
    {
      context: { abilities: firebaseAbilities },
    },
  ] = useStateContext();
  const [ability, setAbility] = React.useState<AbilityType>();

  const handleDelete = (delAbility: AbilityType) => {
    const updatedAbilities = abilities.filter((a) => a.type !== delAbility.type);
    setAbilities(updatedAbilities);
  };

  const handleSelect = (e: any) => {
    const id = e.target.value;
    const selected = firebaseAbilities.find((fbAbility: AbilityType) => fbAbility.id === id);

    if (selected) {
      setAbility(selected);
    }
  };
  return (
    <div>
      <Typography variant="h6">Abilities</Typography>
      {abilities.length && (
        <Container fullWidth>
          {abilities.map((a, i) => (
            <Chip key={i} size="small" label={a.type} onDelete={() => handleDelete(a)} title={a.descr} />
          ))}
        </Container>
      )}
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Add Ability</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleSelect}
            value={(ability && ability.id) || ''}
            fullWidth
          >
            <MenuItem value="">None</MenuItem>
            {firebaseAbilities.map((fbAbility: AbilityType) => {
              return (
                <MenuItem key={fbAbility.id} value={fbAbility.id}>
                  {fbAbility.type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: '1.5rem 0.5rem' }}
          disabled={!ability?.type}
          onClick={() => {
            if (ability) {
              setAbilities([...abilities, ability]);
              setAbility(undefined);
            }
          }}
        >
          Add Ability
        </Button>
      </div>
    </div>
  );
};

export default UnitAbilities;

import React from 'react';
import List from '@material-ui/core/List';
import { FireTeamUnitType } from '../../../types';
import FireTeamUnitListItem from './FireTeamUnitListItem';

const FireTeamUnitList = ({ units }: { units: FireTeamUnitType[] }): JSX.Element => {
  return (
    <List>
      {units.map((unit: FireTeamUnitType, i) => {
        return <FireTeamUnitListItem key={`${unit.id}-{i}`} unit={unit} />;
      })}
    </List>
  );
};

export default FireTeamUnitList;

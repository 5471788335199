import React from 'react';
import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthUserContext } from '../../firebase';
import { HOME } from '../../constants/routes';

const AuthProvider = ({ context, children }: { context: any; children: JSX.Element }) => {
  return <AuthUserContext.Provider value={context}>{children}</AuthUserContext.Provider>;
};

const AuthConsumer = ({ children }: { children: JSX.Element }) => {
  return (
    <AuthUserContext.Consumer>
      {(context: any) => (context && context.user ? children : <Redirect to={HOME} />)}
    </AuthUserContext.Consumer>
  );
};

type Props = {
  [x: string]: any;
};

const withAuth = (Component: ComponentType<any>) => {
  const WithAuthentication: React.FC = (props: Props) => {
    return (
      <AuthConsumer>
        <Component {...props} />
      </AuthConsumer>
    );
  };

  return WithAuthentication;
};

export { AuthProvider, AuthConsumer, withAuth };
